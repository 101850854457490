import React, { useEffect, useState } from "react";
import { AlertMessage, Button, CoreLoader, Input } from "common/components";
import "../../../assets/style/OtpVerification.css";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
const OtpVerification = () => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const loctionData = useLocation();

  const mailData = loctionData.state;
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [minutes, setMinutes] = useState(1);
  const [sec, setSec] = useState(30);

  const handleChange = (event, index) => {
    if (!event?.nativeEvent?.data) {
      return;
    }
    if (isNaN(event.target.value)) return false;
    if (event.target.value.length > 1) {
      return;
    }

    setOtp([...otp.map((d, idx) => (idx === index ? event.target.value : d))]);

    const nextSibling = event.target.nextSibling;
    if (nextSibling) {
      nextSibling.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key == "Backspace" && index >= 0) {
      const updatedOtp = [...otp];
      updatedOtp[index] = "";
      let updateOTPNew = [...updatedOtp];
      setOtp([...updateOTPNew]);
      const prevSibling = event.target.previousSibling;
      if (prevSibling) {
        prevSibling.focus();
      }
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      } else {
        if (minutes > 0) {
          setMinutes(minutes - 1);
          setSec(59);
        } else {
          clearInterval(timer);
        }
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [minutes, sec]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let Otp = otp.join("");
    setLoading(true);
    apiManager
      .verifyotp({ email: mailData, otp: Otp })
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "Otp verification successfully");
        navigate("/newpassword", { state: response?.data });
      })
      .catch((error) => {
        console.log(error, "errorsss");
        setmsgType("error");
        setShowMSG(error?.response?.data || "failed to Otp verification");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const ResendOtp = () => {
    setLoading(true);
    apiManager
      .resend({ email: mailData })
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "Resend otp successfully");
      })
      .catch((error) => {
        console.log(error, "errorsss");
        setmsgType("error");
        setShowMSG(error?.response?.data || "failed to resend otp");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <h2 className="mb-4"> OTP Verification </h2>
      <p className="mb-4">Please enter the verification code sent to your email</p>

      <div className="otp-Container mb-4">
        {otp.map((data, index) => {
          return (
            <>
              <input
                type="number"
                className="code"
                placeholder="0"
                key={index}
                value={data || ""}
                onChange={(e) => handleChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                onFocus={(e) => e.target.select()}
                required
              />
            </>
          );
        })}
      </div>

      <div className="mt-3">
        <p className="otp_Timer">
          {minutes === 0 && sec === 0
            ? ""
            : `${minutes < 10 ? `0${minutes}` : minutes}:${sec < 10 ? `0${sec}` : sec} ${minutes ? "min" : "sec"}`}
        </p>
      </div>
      <div className="d-grid mb-2">
        <Button onClick={handleSubmit} label="Submit" type="submit"></Button>
      </div>
      {minutes !== 0 || sec !== 0 ? (
        ""
      ) : (
        <small className="d-flex justify-content-center">
          Code not received.
          <span className="pointer" style={{ color: "var(--DARK_BLUE)" }} onClick={ResendOtp}>
            Resend
          </span>
        </small>
      )}
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default OtpVerification;
