import React from "react";
import "../../assets/style/EmployeeProfile/AccordionWrapper.css";
const AccordionWrapper = (props) => {
  const { title, children, eventKey, id } = props;
  return (
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={eventKey}
            aria-expanded="false"
            aria-controls="flush-collapseOne"
          >
            {title}
          </button>
        </h2>
        <div id={id} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default AccordionWrapper;
