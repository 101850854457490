import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { Calendar, Whisper, Popover, Badge } from "rsuite";
import "rsuite/dist/rsuite.min.css";

function getHolidayList(date, holidaysArr) {
  if (!holidaysArr) {
    return [];
  }

  let holidays = holidaysArr[0];

  const day = date.getDate();
  let month = date.getMonth() + 1;
  month = String(month).padStart(2, "0");
  const holidayList = [];

  if (holidays[month]) {
    holidays[month].forEach((holiday) => {
      if (+holiday.date == +day) {
        holidayList.push({ name: holiday.holiday_name });
      }
    });
  }

  return holidayList;
}

const CalendarApp = () => {
  const [holidayData, setholidayData] = useState();
  const [calendarDateYear, setCalendarDateYear] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchTitleData();
  }, [calendarDateYear]);

  const fetchTitleData = () => {
    setLoading(true);
    let params = { year: calendarDateYear };
    apiManager
      .Attendancetcalenderlist(params)
      .then((response) => {
        setLoading(false);
        setholidayData(response?.data?.holidaylist);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  function renderCell(date) {
    const holidayList = getHolidayList(date, holidayData);

    if (holidayList.length) {
      return (
        <ul className="calendar-holiday-list" style={{ padding: 0, margin: 0 }}>
          {holidayList.map((holiday, index) => {
            const isLongName = holiday.name.length > 10;

            return (
              <li
                key={index}
                style={{ listStyleType: "none", fontSize: "12px", display: "flex", alignItems: "center" }}
              >
                <Badge style={{ backgroundColor: "red", marginRight: "5px" }} />
                {isLongName ? (
                  <Whisper placement="top" trigger="hover" speaker={<Popover>{holiday.name}</Popover>}>
                    <span style={{ cursor: "pointer" }}>{holiday.name.slice(0, 10)}...</span>
                  </Whisper>
                ) : (
                  <span>{holiday.name}</span>
                )}
              </li>
            );
          })}
        </ul>
      );
    }

    return null;
  }

  return (
    <Calendar
      bordered
      renderCell={renderCell}
      onChange={(date) => {
        date = new Date(date).getFullYear();
        setCalendarDateYear(date);
      }}
    />
  );
};

export default CalendarApp;
