import React from "react";
import { Loader, Placeholder } from "rsuite";
import style from "./css/coreloader.module.css";

function CoreLoader(props) {
  const { show, center, disableLines } = props;

  if (!show) {
    return <></>;
  }

  return (
    <>
      <div className={style.coreloader}>
        <div className="spinner-border" role="status"></div>
        <span className="ms-1">Loading...</span>
      </div>
    </>
  );
}

export default CoreLoader;
