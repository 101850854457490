import React, { useState } from "react";
import { AlertMessage, Button, CoreLoader, FloatingInput } from "common/components";
import apiManager from "networking/baseAPIManager";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email address").required("Email is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      apiManager
        .forgotpassword(values)
        .then((response) => {
          navigate("/otpverification", {
            state: response?.data?.email,
          });
          setLoading(false);
          setmsgType("success");
          setShowMSG(response?.data?.message || "Password updated successfully");
        })
        .catch((error) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(error?.response?.data?.message || "Failed to update password");
          console.log(error, "rrrrrrrrr");
        });
    },
  });

  return (
    <>
      <h2 className="mb-5"> Forgot Password </h2>
      <p>Enter the OTP code sent to your email.</p>
      <form onSubmit={handleSubmit}>
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div>
          <FloatingInput
            value={values.email.toLowerCase()}
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="Please Enter Your Email"
            label="Email address"
            isvalid={touched.email && !errors.email}
            error={errors.email}
            touched={touched.email}
            onBlur={handleBlur}
          />
        </div>
        <div className="d-grid mt-4">
          <Button label="Continue" type="submit" />
        </div>
        <div className="py-3 d-flex justify-content-center">
          <p>
            Back To
            <Link to={"/login"}>
              <b className="ps-2">Login</b>
            </Link>
          </p>
        </div>
        <CoreLoader show={loading} disableLines />
      </form>
    </>
  );
};

export default ForgotPassword;
