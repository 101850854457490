import { SearchFilter } from "Components/AttendaceReport";
import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CardWrapper, CommonModal } from "common/components";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/style/assetmanagement.css";
import { editFlatIcon, gadgets, homeIcon, totalAssigned } from "assets/images";
import BulkAssetModal from "common/components/BulkAssetModal";
import AddAssets from "./AddAssets";
import activeassets from "../../../../src/assets/images/activeassets.png";

export default function AssetManagement() {
  const [open, setOpen] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const tilesJson = [
    {
      title: "ActiveAsset",
      icon: gadgets,
      path: "/hrtools/assets-management/inventory",
    },
    {
      title: "Asset Inventory",
      icon: homeIcon,
      path: "/hrtools/assets-management/inventory",
    },
    {
      title: "Total Assigned Asset",
      icon: totalAssigned,
      path: "/hrtools/assets-management/asset-assignment",
    },
    {
      title: "Total Pending Asset",
      icon: editFlatIcon,
      path: "/hrtools/assets-management/pending-asset-verification",
    },
  ];
  const navigate = useNavigate();
  const renderTiles = () => {
    return tilesJson.map((tiles, index) => {
      return (
        <CardWrapper key={index}>
          <div className="d-flex align-items-center h-100 justify-content-center  p-3">
            <div className="d-flex gap-3  align-items-center" onClick={() => navigate(tiles.path)}>
              <img src={tiles.icon} />
              {tiles.title}
            </div>
          </div>
        </CardWrapper>
      );
    });
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <SubHeader location={useLocation()} />

      <div className="p-3">
        <div className="d-flex justify-content-end pb-3">
          <SearchFilter />
          <div className="d-flex justify-content-between gap-3">
            <Button label="Add Asset" onClick={() => navigate("/hrtools/assets-management/add-asset")} />
            <Button label="Bulk Upload Asset" onClick={() => setOpen(true)} />
          </div>
        </div>
        <div className="cardBoxes">{renderTiles()}</div>

        <CommonModal open={open} onClose={() => setOpen(false)} size="md" title="Bulk Asset Upload">
          <BulkAssetModal setmsgType={setmsgType} setShowMSG={setShowMSG} setOpen={setOpen} />
        </CommonModal>
      </div>
    </>
  );
}
