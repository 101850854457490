import React from "react";
import "../../assets/style/attendance/Emp-raw.css";
export const EmpRawData = ({ title, number }) => {
  return (
    <div className="div-border inline-div ">
      <div className="data-1"> {title}</div>
      <div className="data-1">{number}</div>
    </div>
  );
};
