import React from "react";
import { Link } from "react-router-dom";
import "../../assets/style/NotFound.css"; // Import custom styles

const NotFound = () => {
  return (
    <div className="not-found-container d-flex flex-column justify-content-center align-items-center">
      <div className="overlay"></div>
      <div className="content text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3">
          <span className="text-red">Oops!</span> We can't seem to find the page you're looking for.
        </p>
        <p className="lead">The page might have been removed, or you may have mistyped the address.</p>
        <Link to="/" className="btn btn-blue btn-lg mt-4 shadow">
          Go Home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
