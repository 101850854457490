import React, { useEffect, useState } from "react";
import "../../../../assets/style/attendanceMark.css";
import { RxCrossCircled } from "react-icons/rx";
import { TbCheckbox } from "react-icons/tb";
import axios from "axios";
import { AlertMessage, Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import { useLocation } from "react-router-dom";
import { SubHeader } from "UI_Components";
const userInfo = {
  userId: 1,
  name: "John",
  department: "IT",
  project: "Codesk",
};
const AttendanceMarkUp = () => {
  const [employeesList, setEmployeesList] = useState([]);
  const [presentCount, setPresentCount] = useState(0);
  const [absentCount, setAbsentCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const changePayload = (payload) => {
    payload.map((item) => {
      if (!item.present) {
        item.present = true;
      }
    });
    return payload;
  };

  useEffect(() => {
    setLoading(true);
    apiManager
      .activeEmployeeList("?status=active")
      .then((response) => {
        setLoading(false);
        let tmpData = changePayload(response.data);
        setEmployeesList(tmpData);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, []);

  const toggleAttendance = (empId) => {
    let newArrData = [];
    let totalPresentCount = 0;

    [...employeesList].map((employee) => {
      if (employee._id == empId) {
        employee.present = !employee.present;
        employee = employee;
      }
      if (employee.present) {
        totalPresentCount += 1;
      }

      newArrData.push(employee);
    });
    setAbsentCount(newArrData.length - totalPresentCount);
    setPresentCount(totalPresentCount);
    setEmployeesList([...newArrData]);
  };

  const genratePayload = () => {
    let newData = [];
    employeesList.map((item) => {
      let arr = {
        status: item.present,
        date: item.date || new Date(),
        empid: item._id,
      };
      newData.push(arr);
    });

    return newData;
  };

  const prepareDataToSend = () => {
    let attendance = genratePayload();

    const dataToSend = {};
    dataToSend.department = userInfo.department;
    dataToSend.project = userInfo.project;
    dataToSend.submitter = userInfo.userId;
    dataToSend.attendance = attendance;
    sendDataToApi(dataToSend);
  };

  const sendDataToApi = async (payloadData) => {
    setLoading(true);
    apiManager
      .attendanceMark(payloadData)
      .then((res) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
        // console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
        console.log(err);
      });
  };

  return (
    <>
      <SubHeader location={useLocation()} />
      <div className=" text-center">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <h4 className="m-5">Mark Attendance</h4>
        <div className="main_table markUp-table ">
          <div className="d-flex justify-content-between text-secondary ">
            <div className="p-2 ">
              Date : <small className="fw-bold">{new Date().toDateString()}</small>
            </div>
            <div className="d-flex ">
              <div className="p-2">
                Present : <small className="fw-bold">{presentCount}</small>
              </div>
              <div className="p-2">
                Absent : <small className="fw-bold">{absentCount}</small>
              </div>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Attendance</th>
              </tr>
            </thead>
            <tbody>
              {employeesList &&
                employeesList.map((emp, index) => (
                  <tr key={index}>
                    <td>
                      {emp.firstname} {emp.lastname}
                    </td>
                    <td className="px-5 py-1">
                      <span onClick={() => toggleAttendance(emp._id)}>
                        {emp.present ? (
                          <TbCheckbox fontSize="1.5rem" color="green" />
                        ) : (
                          <RxCrossCircled fontSize="1.5rem" color="red" />
                        )}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Button label="Send" className="Markup-btn" onClick={prepareDataToSend} />
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
};

export default AttendanceMarkUp;
