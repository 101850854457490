import AssetInfo from "Components/AssetManagement/AssetInfo";
import LeaveView from "Components/LeaveRequest/LeaveView";
import { Table } from "UI_Components";
import { Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import AdvanceSalaryView from "./AdvanceSalaryView";
import { Pagination } from "rsuite";

export default function AdvanceSalaryPending({ advanceSalaryPendingData, fetchapi }) {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(true);
  const [view, setView] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancelledId, setcancelled] = useState();
  const [confirm, setConfirm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = advanceSalaryPendingData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, advanceSalaryPendingData]);
  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };

  const customHtml1 = (data) => {
    return <Button onClick={() => handleDeleteModal(data)} label="Delete" />;
  };

  const customHtml2 = (data) => (
    <div className="d-flex gap-3">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );

  const moment = require("moment");
  const formatDate = (date) => moment(date).format("DD MMM YYYY");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Salary Type", field: "advance_salary_type" },
    { header: "Amount", field: "amount" },
    { header: "Duration ", field: "instalment" },
    { header: "Applied Date", field: "createdAt", customHtml: (data) => formatDate(data.createdAt) },
    { header: "", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];
  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
    setDeleteModal(false);
    setView(true);
  };
  const handleDeleteModal = (data) => {
    setOpen(true);
    setView(false);
    setDeleteModal(true);
    setcancelled(data._id);
  };

  const cancelstatus = () => {
    let dataPayload = {
      id: cancelledId,
      status: "cancelled",
    };

    apiManager
      .advanceSalaryStatus(dataPayload)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      });
  };

  useEffect(() => {
    confirm === true && cancelstatus();
    setConfirm(false);
  }, [confirm === true]);
  return (
    <div>
      <div className="container-fluid">
        <div className="row  p-3">
          <div className="col-lg-12 px-2  tableContainer">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="pt-5 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={advanceSalaryPendingData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "md" : "xs"} title="Employee Info">
        {view ? <AdvanceSalaryView Data={modalContent} setOpen={setOpen} /> : null}
        {deleteModal ? <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} /> : null}
      </CommonModal>
      <CoreLoader show={loading} />
    </div>
  );
}
