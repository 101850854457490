// import React, { useEffect } from "react";
// import moment from "moment";

// export const MainContentReport = ({ startDate, reportData, message }) => {
//   useEffect(() => {
//     console.log(reportData, "reportData============>>>>");
//   }, []);

//   const weekArray = ["M", "Tu", "W", "Th", "F", "S", "S"];

//   const startOfMonth = moment(startDate.firstDayOfMonth);
//   const endOfMonth = startOfMonth.clone().endOf("month");
//   const lastDateOfMonth = endOfMonth.date();

//   const getDayName = (date, weekArray) => weekArray[date.day()];
//   const dynamicDate = (baseDate, index) => moment(baseDate).add(index, "days");

//   return (
//     <div>
//       <table className="main_table">
//         <thead>
//           <tr>
//             <th scope="col" className="">
//               S.No.
//             </th>
//             <th scope="col text-center">Name</th>
//             {Array.from({ length: lastDateOfMonth }, (_, index) => (
//               <th key={index}>
//                 {dynamicDate(startOfMonth, index).date()}
//                 <br /> {getDayName(dynamicDate(startOfMonth, index - 1), weekArray)}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {reportData &&
//             reportData.map((employee, index) => (
//               <tr key={employee.employeeID}>
//                 <td>{index + 1}</td>
//                 <td>
//                   {employee.firstname} {employee.lastname}
//                 </td>
//                 {Array.from({ length: lastDateOfMonth }, (_, index) => (
//                   <td key={index}>
//                     {employee?.attendance
//                       ? employee?.attendance?.map((attendance) => {
//                           const attendanceDate = moment(attendance?._doc?.date);
//                           const dayIndex = attendanceDate.date() - 1;

//                           if (dayIndex === index) {
//                             return (
//                               <div key={attendance.in}>
//                                 <p style={{ color: `#${attendance?.color}` }}>{attendance?.leave_type}</p>
//                               </div>
//                             );
//                           } else {
//                             return null;
//                           }
//                         })
//                       : ""}
//                   </td>
//                 ))}
//               </tr>
//             ))}
//         </tbody>
//       </table>
//       {reportData === undefined && (
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             padding: "4rem",
//             flexDirection: "column",
//             fontFamily: "cursive",
//             fontSize: "1.8rem",
//           }}
//         >
//           {message}
//         </div>
//       )}
//     </div>
//   );
// };
import React, { useEffect } from "react";
import moment from "moment";

export const MainContentReport = ({ startDate, reportData, message }) => {
  console.log(reportData, "reportData============>>>>");

  const weekArray = ["M", "Tu", "W", "Th", "F", "S", "S"];

  const startOfMonth = moment(startDate.firstDayOfMonth);
  const endOfMonth = startOfMonth.clone().endOf("month");
  const lastDateOfMonth = endOfMonth.date();

  const getDayName = (date, weekArray) => weekArray[date.day()];
  const dynamicDate = (baseDate, index) => moment(baseDate).add(index, "days");

  return (
    <div>
      <table className="main_table">
        <thead>
          <tr>
            <th scope="col">S.No.</th>
            <th scope="col" className="text-center">
              Name
            </th>
            {Array.from({ length: lastDateOfMonth }, (_, index) => (
              <th key={index}>
                {dynamicDate(startOfMonth, index).date()}
                <br /> {getDayName(dynamicDate(startOfMonth, index), weekArray)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reportData && reportData.length > 0 ? (
            reportData.map((employee, index) => (
              <tr key={employee.employeeID}>
                <td>{index + 1}</td>
                <td>
                  {employee.firstname} {employee.lastname}
                </td>
                {Array.from({ length: lastDateOfMonth }, (_, index) => (
                  <td key={index}>
                    {employee.attendance && employee.attendance.length > 0
                      ? employee.attendance.map((attendance) => {
                          const attendanceDate = moment(attendance?._doc?.date);
                          const dayIndex = attendanceDate.date() - 1;

                          return dayIndex === index ? (
                            <div key={attendance.in}>
                              <p style={{ color: `#${attendance?.color}` }}>{attendance?.leave_type}</p>
                            </div>
                          ) : null;
                        })
                      : null}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={lastDateOfMonth + 2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "4rem",
                    flexDirection: "column",
                    fontFamily: "cursive",
                    fontSize: "1.8rem",
                  }}
                >
                  {message}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
