import React from "react";
import { ButtonToolbar, Dropdown } from "rsuite";

export default function ActionLeave({ rowData, handleApproveReject }) {
  const CustomDropdown = ({ ...props }) => {
    return (
      <Dropdown {...props}>
        <Dropdown.Item onClick={() => handleApproveReject("reject", rowData)}>Reject </Dropdown.Item>
        <Dropdown.Item onClick={() => handleApproveReject("approve", rowData)}>Approve</Dropdown.Item>
      </Dropdown>
    );
  };
  return (
    <div>
      <ButtonToolbar>
        <CustomDropdown title="Action" trigger="click" />
      </ButtonToolbar>
    </div>
  );
}
