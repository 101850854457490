import React, { useId } from "react";
import styles from "./css/input.module.css";

function GroupInput(props) {
  let { label, parentclasses, labelclasses, touched, error, required, group } = props;
  let uniqueId = useId();
  const inputProps = { ...props };
  delete inputProps.required;
  return (
    <div className={parentclasses}>
      <div className="input-group">
        <input id={"input" + uniqueId} className={`form-control ${styles.input}`} {...inputProps} />
        {group && <span className="input-group-text">{group}</span>}
      </div>
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default GroupInput;
