import React, { useEffect, useState } from "react";
import { Input, Label, Button, CoreLoader, AlertMessage } from "common/components";
import apiManager from "networking/baseAPIManager";

const AddHolidayEdit = ({ onSave, data }) => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [holidayName, setHolidayName] = useState("");
  const [date, setDate] = useState("");
  const [day, setDay] = useState("");

  const updateDay = (selectedDate) => {
    const dayOfWeek = new Date(selectedDate).toLocaleDateString("en-US", { weekday: "long" });
    setDay(dayOfWeek);
  };
  useEffect(() => {
    if (data) {
      setHolidayName(data.holiday_name || "");
      setDate(data.date || "");
      updateDay(data.date || "");
    }
  }, [data]);

  const handleSubmit = () => {
    let payload = {
      id: data._id,
      holiday_name: holidayName,
      date: date,
      day: day,
    };
    setLoading(true);
    apiManager
      .updateholiday(payload)
      .then((res) => {
        onSave(res.data);
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
        console.error("Error updating holiday:", err);
      });
  };

  return (
    <div>
      <div className="container px-5 mt-4">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="row ">
          <Label label="Holiday Name" parentclasses="col-lg-4  " />
          <Input
            inputclasses="form-controlcustom"
            parentclasses="col-lg-8"
            value={holidayName}
            onChange={(e) => setHolidayName(e.target.value)}
          />

          <Label label="Date" parentclasses="col-lg-4 mt-4 " />
          <Input
            type="Date"
            inputclasses="form-controlcustom"
            parentclasses="col-lg-8 mt-4"
            value={date}
            onChange={(e) => {
              setDate(e.target.value);
              updateDay(e.target.value);
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="col-lg-10 d-flex justify-content-end">
            <Button onClick={handleSubmit} label="Save"></Button>
          </div>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </div>
  );
};

export default AddHolidayEdit;
