import axios from "axios";
import React, { useEffect, useState } from "react";

const Fiter = ({
  setFilterData,
  filterData,
  handleSubmit,
  setWeekStartDate,
  setWeekContentStartDate,
}) => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [selectedMonth, setSelectedMonth] = useState(0);
  const years = [2023, 2024, 2025, 2026, 2027, 2028];
  const [selectedYear, setSelectedYear] = useState(2023);
  const [departments, setDepartments] = useState([]);
  const [projectsMetaData, setProjectsMetaData] = useState({});
  const [selectedDept, setSelectedDept] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedProj, setSelectedProj] = useState("");

  useEffect(() => {
    axios
      .get("http://localhost:5050/attendance-report-category")

      .then((response) => {
        const data = response.data;
        const allDepartments = [];
        const allProjects = {};
        data.forEach((deptObj) => {
          allDepartments.push(deptObj.department);
          allProjects[deptObj.department] = deptObj.project;
        });
        setDepartments(allDepartments);
        setSelectedDept(allDepartments[0]);
        setProjectsMetaData(allProjects);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedDept && projectsMetaData[selectedDept]) {
      setSelectedProj(projectsMetaData[selectedDept]);
    }
  }, [selectedDept, projectsMetaData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "dep") {
      setSelectedDept(value);
    } else if (name === "pro") {
      setSelectedProj(value);
    } else if (name == "year") {
      setSelectedYear(value);
    } else if (name == "month") {
      setSelectedMonth(value);
    } else {
      console.log("No inputs Value");
    }
  };

  useEffect(() => {
    setFilteredProjects(projectsMetaData[selectedDept]);
    setFilterData({
      ...filterData,
      dep: selectedDept,
      pro: selectedProj,
      year: selectedYear,
      month: parseInt(selectedMonth) + 1,
    });
   if (setWeekStartDate) setWeekStartDate(-7)
  }, [selectedDept, selectedProj, selectedMonth, selectedYear]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div>
          <select
            onChange={(e) => handleInputChange(e)}
            value={selectedDept}
            name="dep"
          >
            {departments.map((dept, index) => (
              <option key={index} value={dept}>
                {dept}
              </option>
            ))}
          </select>
          {filteredProjects && filteredProjects.length > 0 ? (
            <>
              <select
                onChange={(e) => handleInputChange(e)}
                value={selectedProj}
                name="pro"
              >
                {filteredProjects.map((project, index) => (
                  <option key={index} value={project}>
                    {project}
                  </option>
                ))}
              </select>
            </>
          ) : null}
        </div>
        <div>
          <select
            onChange={(e) => handleInputChange(e)}
            value={selectedMonth}
            name="month"
          >
            {months.map((month, i) => (
              <option key={i} value={i}>
                {month}
              </option>
            ))}
          </select>
          <select
            onChange={(e) => handleInputChange(e)}
            name="year"
            value={selectedYear}
          >
            {years.map((year, i) => (
              <option key={i} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
        <button onClick={(e) => handleSubmit()}> Get report </button>
      </div>
    </div>
  );
};

export default Fiter;
