import { AlertMessage, Button, CommonModal } from "common/components";
import React, { useState } from "react";
import userImage from "../../assets/images/userImage.jpg";
import AssetAssign from "./AssetAssign";

const AssetInfo = ({ Data, setIsOpen, setShowMSG, setmsgType }) => {
  const [open, setOpen] = useState(false);

  let BASE_URL = process.env.REACT_APP_BASE_URL;
  const AssetData = Data;
  const secLabels = {
    assetname: "Asset Name",
    assetId: "Asset ID",
    warranty: "Warranty",
    status: "Status",
    model: "Model",
    purchase_date: "Purchase Date",
    purchase_from: "Purchase From",
    value: "Cost",
  };

  const arr = AssetData
    ? Object.entries(AssetData)
        .filter(([originalLabel]) => secLabels[originalLabel])
        .map(([originalLabel, value]) => ({
          label: secLabels[originalLabel],
          value: value,
        }))
    : [];

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="row">
            {AssetData ? (
              <>
                <div className="col-lg-12 ps-5 pt-3">
                  <img src={`${BASE_URL}/${AssetData.image}`} alt="Asset" width="250" height="200" />
                </div>
                <div className="col-lg-12">
                  <ul>
                    <li className="row">
                      <span className="col-lg-6 pt-3">Manufacturer </span>
                      <span className="col-lg-6 text-dark-emphasis pt-3">{AssetData.manufacturer}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-6 pt-3">Serial Number </span>
                      <span className="col-lg-6 text-dark-emphasis pt-3">{AssetData.serial_number}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-6 pt-3">Supplier </span>
                      <span className="col-lg-6 text-dark-emphasis pt-3">{AssetData.supplier}</span>
                    </li>
                    <li className="row">
                      <span className="col-lg-6 pt-3">Description </span>
                      <span className="col-lg-6 text-dark-emphasis pt-3">{AssetData.description}</span>
                    </li>
                  </ul>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-lg-6">
          {arr &&
            arr.map((content, index) => (
              <div className="row " key={index}>
                <div className="col-lg-6 pt-3  ">{content.label} </div>
                <div className="col-lg-6 pt-3 text-dark-emphasis ">{content.value}</div>
              </div>
            ))}
        </div>
      </div>

      <div className="row px-5 mt-4">
        <div className="d-flex justify-content-end gap-2">
          <Button label="Assign" onClick={() => setOpen(true)}></Button>
          <Button label="Cancel" onClick={() => setIsOpen(false)}></Button>
        </div>
      </div>

      <CommonModal open={open} onClose={() => setOpen(false)} size="sm" title="Asset Assignment" className="pt-5 mt-5">
        <AssetAssign setOpen={setOpen} assetObjectId={Data._id} setmsgType={setmsgType} setShowMSG={setShowMSG} />
      </CommonModal>
    </div>
  );
};

export default AssetInfo;
