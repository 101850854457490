import { SearchFilter } from "Components/AttendaceReport";
import { Table } from "UI_Components";
import { Button, CommonModal, Dropdown } from "common/components";
import React, { useEffect, useState } from "react";
import AdvanceSalaryView from "./AdvanceSalaryView";
import { Pagination } from "rsuite";

export default function AdvanceSalaryComplete({ completeData, fetchapi }) {
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = completeData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, completeData]);

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Salary Type", field: "advance_salary_type" },
    { header: "Amount", field: "amount" },
    { header: "Duration ", field: "instalment" },
    { header: "Applied Date", field: "createdAt", customHtml: (data) => dateHtml(data.createdAt) },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status.toLowerCase() === "approved"
            ? "green"
            : data.status.toLowerCase() === "rejected"
            ? "red"
            : "blue";

        return <span style={{ color: statusColor }}>{data.status}</span>;
      },
    },
    { header: "", field: "actions", btnType: "primary", customHtml },
  ];

  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row p-3">
          <div className="col-lg-12 px-2  tableContainer">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="pt-5 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={completeData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      {
        <CommonModal open={open} onClose={handleClose} title="Employee Info" size="md">
          <AdvanceSalaryView setOpen={setOpen} Data={modalContent} fetchapi={fetchapi} showButton={true} />
        </CommonModal>
      }
    </div>
  );
}
