import React, { useEffect, useState } from "react";
import Table from "UI_Components/Table";
import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
import LeaveView from "./LeaveView";
import ConfirmationModal from "../../common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";
import { Pagination } from "rsuite";

const LeavePending = ({ leavePendingApiData, fetchapi }) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(true);
  const [view, setView] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancelledId, setcancelled] = useState();
  const [confirm, setConfirm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = leavePendingApiData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, leavePendingApiData]);
  const handleOpenDelete = () => {
    setOpen(true);
    setDeleteModal(true);
  };

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };

  const customHtml1 = (data) => {
    return <Button onClick={() => handleDeleteModal(data)} label="Delete" />;
  };

  const customHtml2 = (data) => (
    <div className="d-flex gap-3">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );

  const moment = require("moment");
  const formatDate = (date) => moment(date).format("DD MMM YYYY");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Leave Type", field: "type" },
    { header: "Start Date", field: "start_date", customHtml: (data) => formatDate(data.start_date) },
    { header: "End Date", field: "end_date", customHtml: (data) => formatDate(data.end_date) },
    { header: "Total Leave", field: "leave_days", className: "total-leave-column" },
    { header: "", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];

  const handleItemClick = (data) => {
    setModalContent(data);
    setOpen(true);
    setDeleteModal(false);
    setView(true);
  };
  const handleDeleteModal = (data) => {
    setOpen(true);
    setView(false);
    setDeleteModal(true);
    setcancelled(data._id);
  };

  const cancelstatus = () => {
    let dataPayload = {
      leaveid: cancelledId,
      status: "cancelled",
    };

    apiManager
      .leaveStatus(dataPayload)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "details updated successfully");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      });
  };

  useEffect(() => {
    confirm === true && cancelstatus();
    setConfirm(false);
  }, [confirm === true]);
  return (
    <div className="Container  ">
      <div className="col-lg-12 px-2 pt-3 tableContainer">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <Table
          columns={columns}
          LeaveTableHeight="LeaveTableHeight"
          secondary_btn_label="Delete"
          Data={displayedData}
          btn_name="view"
          handleOpenDelete={handleOpenDelete}
          onClick={(data) => {
            handleItemClick(data);
          }}
        />
      </div>
      <div className="pt-4 d-flex justify-content-end">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={leavePendingApiData.length}
          limit={itemsPerPage}
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Leave Details">
        {view ? <LeaveView Data={modalContent} /> : null}
        {deleteModal ? <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} /> : null}
      </CommonModal>
      <CoreLoader show={loading} />
    </div>
  );
};

export default LeavePending;
