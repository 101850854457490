import { AlertMessage, Button, CoreLoader, DownloadIcon, Input } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import ConfirmationModal from "common/components/ConfirmationModal";

function LeaveView({ Data, showButton, fetchapi, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancele_id, setCancel_id] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [WithdrawModalOpen, setWithdrawModalOpen] = useState(false);
  console.log(Data?.document, "DataDocument");
  const dateHtml = (data, field) => {
    let options = { day: "2-digit", month: "2-digit", year: "2-digit", weekday: "short" };
    let formattedDate = new Date(data[field]).toLocaleDateString("en-GB", options).replaceAll("/", "-");

    return <>{formattedDate}</>;
  };

  const leaveLabelMapping = {
    type: "Leave Type",
    start_date: "Start Date",
    end_date: "End Date",
    session: "Half-day",
    reason: "Reason",
    document: "Attachment",
  };
  const resignationLabelMapping = {
    resignation_type: "Resignation Type",
    notice_date: "Notice Date",
    resignation_date: "Resignation  Date",
    reason: "Reason",
  };

  const labelMapping = Data.resignation_type ? resignationLabelMapping : leaveLabelMapping;
  const openWithdrawModal = () => {
    setWithdrawModalOpen(true);
    setCancel_id(Data._id);
  };

  const cancelstatus = () => {
    let dataPayload = {
      leaveid: cancele_id,
      status: "cancelled",
    };
    setLoading(true);

    if (Data.status) {
      let dataPayload = {
        leaveid: cancele_id,
        status: "cancelled",
      };
      setLoading(true);
      apiManager
        .leaveStatus(dataPayload)
        .then((res) => {
          setLoading(false);
          fetchapi && fetchapi();
          setmsgType("success");
          setShowMSG(res?.data?.message || "details updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.response?.data?.message || "failed to update");
          console.log(err, "leave cancelled error");
        });
    }
    if (Data.offboarding_status) {
      let dataPayload = {
        id: cancele_id,
        status: "cancelled",
      };
      setLoading(true);
      apiManager
        .OffboardingStatus(dataPayload)
        .then((res) => {
          setLoading(false);
          fetchapi && fetchapi();
          setmsgType("success");
          setShowMSG(res?.data?.message || "details updated successfully");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.data?.message || "failed to update");
          console.log(err, "offboarding status error");
        });
    }
  };

  useEffect(() => {
    if (confirm) {
      cancelstatus();
      setConfirm(false);
    }
  }, [confirm]);
  const ApprovedOrRejected =
    Data && Data.status
      ? ["approved"].includes(Data.status.toLowerCase())
      : ["approved"].includes(Data.offboarding_status.toLowerCase());
  const arr = Object.entries(Data)
    .filter(([originalLabel]) => labelMapping[originalLabel])
    .map(([originalLabel, value]) => ({
      label: labelMapping[originalLabel],
      value: originalLabel.includes("date") ? dateHtml(Data, originalLabel) : value,
    }));

  const downloadAttachment = async () => {
    let BASE_URL = process.env.REACT_APP_BASE_URL;
    const attachmentData = `${BASE_URL}/${Data?.document}`;

    if (!attachmentData) {
      console.error("No attachment data found.");
      return;
    }

    try {
      const response = await fetch(attachmentData);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", Data?.document);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error fetching or triggering download:", error);
    }
  };

  return (
    <>
      <div className="container pt-3">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

        {arr &&
          arr.map((content, index) => (
            <div className="row px-5" key={index}>
              <div className="col-md-6 p-2 ps-5 ">{content.label}</div>
              <div className="col-md-6 p-2 ">
                {content.label === "Attachment"
                  ? Data.document && <Button label="Download" onClick={downloadAttachment} />
                  : content.value}
              </div>
            </div>
          ))}

        {ApprovedOrRejected && (
          <div className="row px-5">
            <div className="col-md-10 p-2"></div>
            <div className="col-md-2 p-2">
              {showButton && (
                <>
                  <Button label="withdraw" onClick={() => openWithdrawModal()}></Button>
                </>
              )}
            </div>

            {WithdrawModalOpen && (
              <div className="col-md-12 drawpop p-2">
                <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} />
              </div>
            )}
            <CoreLoader show={loading} disableLines />
          </div>
        )}
      </div>
    </>
  );
}

export default LeaveView;
