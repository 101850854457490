import React from "react";
import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CoreLoader, Dropdown, Input, Label } from "common/components";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { EditSalarySchema } from "utils/formValidationUtils";

const EditSalaryForm = () => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state.data.salary_status, ".Status");
  console.log(state.data, "StatusData=====>>>>>");
  useEffect(() => {
    if (state.data) {
      setValues({
        employee_name: state ? state.data.empname : "",
        department: state ? state.data.department : "",
        pan_number: state ? state.data.pancard_No : "",
        bank_account_number: state ? state.data.account_no : "",
        uan: state ? state.data.uan : "",
        present_day: state.data ? state.data.presentDay : "",
        joining_date: state ? state.data.date_of_joining : "",
        paid_leave: state ? state.data.paidLeave : "",
        basic_salary: state ? state.data.basicSalary : "",
        transport_allowance: state ? state.data.ta : "",
        total_earning: state ? state.data.totalEarning : "",
        tds: state ? state.data.tds : "",
        miscellaneous: state ? state.data.miscellaneous : "",
        pf: state ? state.data.pf : "",
        employeeId: state ? state.data.employeeID : "",
        designation: state ? state.data.designation : "",
        pay_mode: state ? state.data.payment_mode : "",
        pf_number: state ? state.data.pf_number : "",
        gross_salary: state ? state.data.grossSalary : "",
        total_leave: state ? state.data.totalLeave : "",
        unpaid_leave: state ? state.data.unpaidLeave : "",
        hra: state ? state.data.hra : "",
        bonus: state ? state.data.bonus : "",
        net_salary: state ? state.data.totalnetsalary : "",
        leave_deduction: state ? state.data.leaveDeduction : "",
        advance_salary: state ? state.data.advanceSalary : "",
        esic: state ? state.data.esi : "",
        total_deductions: state ? state.data.totaldeduction : "",
        description: state ? state.data.description : "",
      });
    }
  }, []);

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, setValues } = useFormik({
    initialValues: {
      employee_name: "",
      department: "",
      pan_number: "",
      bank_account_number: "",
      uan: "",
      present_day: "",
      joining_date: "",
      paid_leave: "",
      basic_salary: "",
      transport_allowance: "",
      total_earning: "",
      tds: "",
      miscellaneous: "",
      pf: "",
      employeeId: "",
      designation: "",
      pay_mode: "",
      pf_number: "",
      gross_salary: "",
      total_leave: "",
      unpaid_leave: "",
      hra: "",
      bonus: "",
      net_salary: "",
      leave_deduction: "",
      advance_salary: "",
      esic: "",
      total_deductions: "",
      description: "",
    },
    validationSchema: EditSalarySchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values._id, "values._id");
      let formdata = {
        id: state.data._id,
        transportAllowance: values.transport_allowance,
        tds: values.tds,
        miscellaneous: values.miscellaneous,
        bonus: values.bonus,
        description: values.description,
      };
      setLoading(true);
      apiManager
        .updateSalary(formdata)
        .then((res) => {
          setLoading(false);
          setmsgType("success");
          setShowMSG(res?.data?.message || "asset added successfully");
          if (state.data.salary_status === "pending") {
            changeStatus();
          }
          navigate("/hrtools/manage-fund/edit-salary");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.data?.message || "failed to add asset");
        });
    },
  });
  const changeStatus = () => {
    let status = "edited";
    setLoading(true);
    apiManager
      .changeStatus({ id: state.data._id, status })
      .then((response) => {
        setLoading(false);
        console.log(response, "responseChangeStatus");
      })
      .catch((error) => {
        setLoading(false);
        console.error(`Error ${status} payment:`, error);
      });
  };
  return (
    <>
      <SubHeader location={useLocation()} />

      <div className="col-lg-12 px-5 pt-3 ">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="col-lg-12 pt-4 form-main ">
          <div className="gap-3 text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
            <h2>Edit Salary</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="container pb-5">
              <div className="form-container">
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="col-md-5 mt-4 ms-2">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Employee Name" required />

                      <Input
                        name="employee_name"
                        type="text"
                        value={values.employee_name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.employee_name}
                        touched={touched.employee_name}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Department" required />
                      <Input
                        type="text"
                        name="department"
                        value={values.department}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.department}
                        touched={touched.department}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="PAN No. " required />
                      <Input
                        name="pan_number"
                        type="text"
                        value={values.pan_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.pan_number}
                        touched={touched.pan_number}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Bank A/C No." required />
                      <Input
                        name="bank_account_number"
                        type="number"
                        value={values.bank_account_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.bank_account_number}
                        touched={touched.bank_account_number}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="UAN" required />
                      <Input
                        name="uan"
                        value={values.uan}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.uan}
                        touched={touched.uan}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Present Day" required />
                      <Input
                        type="text"
                        name="present_day"
                        value={values.present_day}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.present_day}
                        touched={touched.present_day}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label=" Joining Date" required />
                      <Input
                        name="joining_date"
                        type="date"
                        value={values.joining_date}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.joining_date}
                        touched={touched.joining_date}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Paid Leave" required />
                      <Input
                        name="paid_leave"
                        type="number"
                        value={values.paid_leave}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.paid_leave}
                        touched={touched.paid_leave}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Basic Salary" required />
                      <Input
                        name="basic_salary"
                        type="number"
                        value={values.basic_salary}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.basic_salary}
                        touched={touched.basic_salary}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Transport Allowance" required />
                      <Input
                        name="transport_allowance"
                        type="number"
                        value={values.transport_allowance}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.transport_allowance}
                        touched={touched.transport_allowance}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Total Earning" required />
                      <Input
                        name="total_earning"
                        type="number"
                        value={values.total_earning}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.total_earning}
                        touched={touched.total_earning}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="TDS" required />
                      <Input
                        name="tds"
                        type="number"
                        value={values.tds}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.tds}
                        touched={touched.tds}
                      />
                    </div>

                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="PF" required />
                      <Input
                        name="pf"
                        type="number"
                        value={values.pf}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.pf}
                        touched={touched.pf}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Miscellaneous" required />
                      <Input
                        name="miscellaneous"
                        type="number"
                        value={values.miscellaneous}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.miscellaneous}
                        touched={touched.miscellaneous}
                      />
                    </div>
                  </div>
                  <div className="col-md-5  mt-4 ms-2">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Employee ID." required />
                      <Input
                        name="employeeId"
                        type="text"
                        value={values.employeeId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.employeeId}
                        touched={touched.employeeId}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Designation" required />
                      <Input
                        name="designation"
                        type="text"
                        value={values.designation}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.designation}
                        touched={touched.designation}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Pay Mode" required />
                      <Input
                        name="pay_mode"
                        type="text"
                        value={values.pay_mode}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.pay_mode}
                        touched={touched.pay_mode}
                        disabled={true}
                      />
                    </div>

                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Gross Salary(monthly)" required />
                      <Input
                        name="gross_salary"
                        type="text"
                        value={values.gross_salary}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.gross_salary}
                        touched={touched.gross_salary}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Total Leave" />
                      <Input
                        name="total_leave"
                        type="text"
                        onBlur={handleBlur}
                        value={values.total_leave}
                        onChange={handleChange}
                        error={errors.total_leave}
                        touched={touched.total_leave}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Unpaid Leave" required />
                      <Input
                        name="unpaid_leave"
                        type="number"
                        value={values.unpaid_leave}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.unpaid_leave}
                        touched={touched.unpaid_leave}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="HRA" required />
                      <Input
                        name="hra"
                        type="number"
                        value={values.hra}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.hra}
                        touched={touched.hra}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Bonus" required />
                      <Input
                        name="bonus"
                        type="number"
                        value={values.bonus}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.bonus}
                        touched={touched.bonus}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Net Salary" required />
                      <Input
                        name="net_salary"
                        type="number"
                        value={values.net_salary}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.net_salary}
                        touched={touched.net_salary}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Leave Deduction" required />
                      <Input
                        name="leave_deduction"
                        type="number"
                        value={values.leave_deduction}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.leave_deduction}
                        touched={touched.leave_deduction}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Advance Salary" required />
                      <Input
                        name="advance_salary"
                        type="number"
                        value={values.advance_salary}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.advance_salary}
                        touched={touched.advance_salary}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="ESIC" required />
                      <Input
                        name="esic"
                        type="text"
                        value={values.esic}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.esic}
                        touched={touched.esic}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="PF No." required />
                      <Input
                        name="pf_number"
                        type="text"
                        value={values.pf_number}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.pf_number}
                        touched={touched.pf_number}
                        disabled={true}
                      />
                    </div>
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Total Deductions" required />
                      <Input
                        name="total_deductions"
                        value={values.total_deductions}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.total_deductions}
                        touched={touched.total_deductions}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ms-2 me-2 whi">
                    <div className=" form-outline mb-4 row align-items-center ms-0">
                      <Label label="Description" />
                      <Input
                        name="description"
                        type="text"
                        value={values.description}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={errors.description}
                        touched={touched.description}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-12 d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
              <Button className="btn btn-primary sub-btn" variant="primary" type="submit" label="Submit" />

              <Button
                className=" btn btn-primary can-btn"
                variant="primary"
                label="Close"
                onClick={() => navigate("/hrtools/manage-fund/edit-salary")}
              />
            </div>
          </form>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </>
  );
};
export default EditSalaryForm;
