import React from "react";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../../assets/style/Responsive.css";
export default function CircularBar({ percentage, days, color, height, width }) {
  const value = percentage ? percentage : days;
  const displayText = `${percentage !== undefined ? percentage : days}%`;
  return (
    <div>
      <div style={{ width: width, height: height }}>
        <CircularProgressbar
          className="CircularProgressbar "
          value={value}
          color={color}
          text={displayText}
          // text={`${percentage || 0}%`}
          styles={buildStyles({
            rotation: 0.25,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "butt",

            // Text size
            textSize: "16px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            pathColor: color,
            textColor: "#f88",
            trailColor: "#d6d6d6",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
    </div>
  );
}
