import { CardWrapper, CoreLoader } from "common/components";
import React, { useState, useEffect } from "react";
import apiManager from "networking/baseAPIManager";
import ProfileDrawer from "./ProfileDrawer";
import userImage from "../../assets/images/UserDefultImage.png";

let BASE_URL = process.env.REACT_APP_BASE_URL;

const ProfileCard = ({ flagFromAttendance, filteredApprovalData, employeeOnleave }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});

  return (
    <div className="cardBoxes boxshadow borderradius p-4">
      {flagFromAttendance === "leave"
        ? employeeOnleave.map((detail, index) => {
            const fullName = `${detail?.empid?.firstname || ""} ${detail?.empid?.lastname || ""}`;
            return (
              <CardWrapper
                onClick={() => {
                  setOpen(true);
                  setData(detail);
                }}
                key={index}
              >
                <img
                  className="userImageRound mt-3"
                  src={detail?.empid?.image ? `${BASE_URL}/${detail?.empid?.image}` : userImage}
                  alt={fullName}
                />
                <h5 className=" mt-2">{fullName}</h5>
                <p className="mt-2">{detail?.empid?.department}</p>
              </CardWrapper>
            );
          })
        : filteredApprovalData.map((detail, index) => {
            const fullName = `${detail?.firstname || ""} ${detail?.middlename || ""} ${detail?.lastname || ""}`;
            return (
              <CardWrapper
                onClick={() => {
                  setOpen(true);
                  setData(detail);
                }}
                key={index}
              >
                <img
                  className="userImageRound mt-3"
                  src={detail?.image ? `${BASE_URL}/${detail?.image}` : userImage}
                  alt={fullName}
                />
                <h5 className=" mt-2">{fullName}</h5>
                <p className="mt-2">{detail?.designation ? detail?.designation : "NA"}</p>
                {/* <p className="mt-2">{detail?.department ? detail?.department : "NA"}</p> */}
              </CardWrapper>
            );
          })}

      <ProfileDrawer setOpen={setOpen} data={data} open={open} />
      <CoreLoader show={loading} />
    </div>
  );
};

export default ProfileCard;
