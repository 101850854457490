import React from "react";
import "../../assets/style/sidebar.css";
import SidebarMainContent from "./SidebarContent";
const Sidebar = () => {
  return (
    <div>
      <SidebarMainContent />
    </div>
  );
};

export default Sidebar;
