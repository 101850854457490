import React, { useEffect } from "react";
import Notification from "rsuite/Notification";

function AlertMessage({ type, title = "", show, setShow }) {
  useEffect(() => {
    if (show && setShow) {
      setTimeout(() => {
        setShow(false);
      }, 5000);
    }
  }, [show]);

  if (!show) {
    return <></>;
  }
  return (
    <div style={{ position: "fixed", top: "90px", right: "20px" }}>
      <Notification
        style={{ minWidth: "250px" }}
        type={type || "info"}
        header={title || type || "info"}
        placement={"topEnd"} // Default to topEnd if placement is not provided
        closable
      >
        {show}
      </Notification>
    </div>
  );
}

export default AlertMessage;
