export const getFirstMondayOfMonth = (year, month) => {
  const firstDayOfMonth = new Date(year, month, 1);

  const dayOfWeek = firstDayOfMonth.getDay();
  const daysUntilMonday = (7 - dayOfWeek + 1) % 7;
  const firstMonday = new Date(year, month - 1, 1 + daysUntilMonday);

  const lastDateOfMonth = new Date(year, month, 0).getDate();
  return { firstMonday, daysUntilMonday, lastDateOfMonth, firstDayOfMonth };
};

export const dynamicDate = (anyDate, dateNum) => {
  const date = new Date(anyDate);

  date.setDate(date.getDate() + dateNum);
  return date;
};

export const formateDate = (date) => {
  return date.toLocaleDateString(date);
};
export const getDayName = (finiteDate, day) => {
  const date = new Date(finiteDate);
  const dayNameIndex = date.getDay();
  return day[dayNameIndex];
};
export const convertDateFormat = (inputDate) => {
  // Parse the input date
  const parsedDate = new Date(inputDate);

  // Check if the parsed date is valid
  if (isNaN(parsedDate.getTime())) {
    // Invalid date, return the original input
    return inputDate;
  }

  // Get the components of the date
  const year = parsedDate.getFullYear();
  const month = String(parsedDate.getMonth() + 1).padStart(2, "0");
  const day = String(parsedDate.getDate()).padStart(2, "0");

  // Format the date as "mm/dd/yyyy"
  const formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};
