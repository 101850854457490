import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const VerifyAssetInfo = ({ Data }) => {
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const navigate = useNavigate();

  const labels = {
    assetname: "Asset Name",
    assetId: "Asset ID",
    purchase_date: "Purchase Date",
    manufacturer: "Manufacturer",
    serial_number: "Serial Number",
    description: "Description",
  };
  const secLabels = {
    supplier: "Supplier",
    warranty: "Warranty",
    status: "Status",
    model: "Model",
    purchase_from: "Purchase From",
  };
  const arr = Data
    ? Object.entries(Data)
        .filter(([originalLabel]) => labels[originalLabel])
        .map(([originalLabel, value]) => ({
          label: labels[originalLabel],
          value: value,
        }))
    : [];
  const arr1 = Data
    ? Object.entries(Data)
        .filter(([originalLabel]) => secLabels[originalLabel])
        .map(([originalLabel, value]) => ({
          label: secLabels[originalLabel],
          value: value,
        }))
    : [];

  const verifyAsset = () => {
    console.log("APICall");
    let payload = {
      id: Data._id,
      verified: "true",
    };

    setLoading(true);
    apiManager
      .verifyAsset(payload)
      .then((res) => {
        setShowMSG(res?.data?.message);
        setmsgType("sucsses");
        navigate("/hrtools/assets-management/inventory");
      })
      .catch((err) => {
        console.warn(err);
        setShowMSG(err?.message);
        setmsgType("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    confirm === true && verifyAsset();
  }, [confirm === true]);
  return (
    <div className="container">
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="row px-2">
        <div className="col-lg-6">
          {arr &&
            arr.map((content, index) => (
              <div className="row " key={index}>
                <div className="col-lg-6 pt-4">{content.label} </div>
                <div className="col-lg-6 pt-4 text-dark-emphasis ">{content.value}</div>
              </div>
            ))}
        </div>
        <div className="col-lg-6">
          {arr1 &&
            arr1.map((content, index) => (
              <div className="row " key={index}>
                <div className="col-lg-6 pt-4">{content.label} </div>
                <div className="col-lg-6 pt-4 text-dark-emphasis ">{content.value}</div>
              </div>
            ))}
        </div>
      </div>

      <div className="row px-5 mt-4">
        <div className="d-flex justify-content-end gap-2">
          <Link to={`/hrtools/assets-management/update-asset/${Data._id}`}>
            <Button label="Edit"></Button>
          </Link>

          <Button
            label="Verify"
            onClick={() => {
              setOpen(true);
            }}
          ></Button>
        </div>
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={"xs"} title="Are You Sure ?" className="pt-5 mt-5">
        <ConfirmationModal setOpen={setOpen} setConfirm={setConfirm} />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </div>
  );
};

export default VerifyAssetInfo;
