import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { subHeaderMenu } from "../../common/json/MenuContent";
import { current } from "@reduxjs/toolkit";
import { BsCheckLg } from "react-icons/bs";
const SubHeader = ({ location }) => {
  const pathname = location.pathname;
  const params = useParams();

  const current_title = subHeaderMenu.filter((menu) => pathname === menu.path);

  return (
    <div>
      <nav
        style={{
          display: "flex",
          marginTop: "10px",
          padding: "5px",
          backgroundColor: "#E6ECF5",
          boxShadow: "0px 0px 4px #E9E5E5",
        }}
      >
        <h4
          className="p-1 "
          style={{
            fontFamily: "Poppins, sans-serif",
            color: "#1C3F98",
            marginLeft: "10px",
          }}
        >
          {current_title.length > "0" ? current_title[0].title : !params ? "Landing Page" : "Holiday calender"}
        </h4>
      </nav>
    </div>
  );
};

export default SubHeader;
