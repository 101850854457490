import React, { useState } from "react";

  //  
const ResetPassword = () => {
  const [cnfPasword,setCnfPasword] = useState()
  const [password,setpassword] =useState() 
  const [succesPassword,setSuccessPassword] = useState()
const handleResetSucces = () => {
 
 if(password===cnfPasword){
  setSuccessPassword(true)
 }
}
 return (
    <div>
      {succesPassword?<div>
        <input type="text" placeholder="enter new password" value={password} onChange={(e)=>setpassword(e.target.value)} />
        <input type="text" placeholder="confirm password" value={cnfPasword} onChange={(e)=>setCnfPasword(e.target.value)}/>
      <button onClick={() => handleResetSucces}>reset</button>
      </div>:<div><p>password succesfully set</p>
      <button>Ok</button></div>}
    </div>
  );
};

export default ResetPassword;
