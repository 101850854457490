import ComingSoonPage from "Pages/ComingSoonPage/ComingSoonPage";
import React from "react";

const Taskmanager = () => {
  return (
    <div>
      <ComingSoonPage />
    </div>
  );
};
export default Taskmanager;
