import EmployeePayRoll from "Components/ManageFund/EmployeePayRoll";
import { SubHeader } from "UI_Components";
import { CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

export default function ManageFundPayroll() {
  const [payrollCost, setPayrollCost] = useState(null);
  const [totalDays, setTotalDays] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    datafetchPayrollCost();
    datafetchTotalDays();
  }, []);

  let month = totalDays?.currentMonth;

  const monthName = moment()
    .month(month - 1)
    .format("MMMM");

  const datafetchPayrollCost = () => {
    setLoading(true);
    apiManager

      .PayrollCostEmployeecost()
      .then((response) => {
        setLoading(false);

        setPayrollCost(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const datafetchTotalDays = () => {
    setLoading(true);
    apiManager
      .PayrollTotalDay()
      .then((response) => {
        setLoading(false);
        setTotalDays(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <SubHeader location={useLocation()} />
      <div class="container-fluid">
        <div className="row g-3 p-4">
          <div className="col-lg-5">
            <div className="border  rounded-4 mb-3 p-3 ">
              <div className="row align-item-center  p-3">
                <div className="col-lg-12 mb-3">Month : {`${monthName} ${totalDays?.currentYear}`}</div>
                <div className="col-lg-6 mb-3 custom-card-title">₹{payrollCost?.payrollCost} </div>
                <div className="col-lg-6 custom-card-title">₹ {payrollCost?.employeeNetpay}</div>
                <div className="col-lg-6 custom-card-text">Payroll Cost</div>
                <div className="col-lg-6 custom-card-text">Employees Net Pay </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="border  rounded-4 mb-3 p-3 ">
              <div className="row align-item-center  p-3">
                <div className="col-lg-12 mb-3 custom-card-text">Pay Day</div>
                <div className="col-lg-12 mb-3 custom-card-title">{totalDays?.totalPayDays}</div>
                <div className="col-lg-12 custom-card-text">{`${monthName} ${totalDays?.currentYear}`}</div>
              </div>
            </div>
          </div>
        </div>
        <EmployeePayRoll />
      </div>
      <CoreLoader show={loading} disableLines />
    </div>
  );
}
