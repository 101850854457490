import React, { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import Header from "./Components/Header";
import "./App.css";
import { AppRoutes } from "./routes";
import Sidebar from "./Components/Sidebar";
import apiManager from "networking/baseAPIManager";
import { setUserResources } from "redux/slice";

function App() {
  // let isAuthorised = true;
  let isAuthorised = useSelector((state) => state?.data?.token);
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state?.data);
  let isLoggedIn = !!reduxData?.token;
  const [propsLoaded, setPropsLoaded] = useState(false);

  const userInformation = () => {
    if (!isLoggedIn) return;
    apiManager
      .getUserInfo()
      .then((res) => {
        dispatch(setUserResources(res.data));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (reduxData.userResources) {
      setPropsLoaded(true);
    } else {
      setPropsLoaded(false);
    }
  }, [reduxData.userResources]);

  useEffect(() => {
    if (isLoggedIn) {
      userInformation();
    }
  }, [isLoggedIn]);

  let authorisedView = () => {
    if (propsLoaded) {
      return (
        <div style={{ overflow: "hidden", height: "100dvh" }}>
          <div className="Header">
            <Header />
          </div>
          {isAuthorised && (
            <div className="content-container">
              <div className="sidebar">
                <Sidebar />
              </div>
              <div className="main-content" style={{ overflow: "auto", height: "100dvh", paddingBottom: "100px" }}>
                <AppRoutes />
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return null;
    }
  };

  let unauthorizedView = () => {
    return (
      <>
        <AppRoutes />
      </>
    );
  };

  return isAuthorised ? authorisedView() : unauthorizedView();
}
export default App;
