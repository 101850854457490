// import { SearchFilter } from "Components/AttendaceReport";
// import GenerateLetter from "Components/GenerateDocuments/GenerateLetter";
// import { SubHeader, Table } from "UI_Components";
// import { Button, CommonModal, CoreLoader } from "common/components";
// import EmployeeDetail from "common/components/EmployeeDetail";
// import apiManager from "networking/baseAPIManager";
// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { Pagination } from "rsuite";

// const GenerateDocuments = () => {
//   const [searchString, setSearchString] = useState("");
//   const [openModalType, setOpenModalType] = useState(null);
//   const [documentList, setDocumentList] = useState([]);
//   const [filterDocumentList, setFilterDocumentList] = useState([]);
//   const [selectedItem, setSelectedItem] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [showMSG, setShowMSG] = useState(false);
//   const [msgType, setmsgType] = useState("info");
//   const [pageRefresh, setPageRefresh] = useState(false);
//   const [activePage, setActivePage] = useState(1);
//   const [displayedData, setDisplayedData] = useState([]);
//   const itemsPerPage = 10;

//   useEffect(() => {
//     const start = (activePage - 1) * itemsPerPage;
//     const end = start + itemsPerPage;
//     const paginatedData = filterDocumentList.slice(start, end).map((item, index) => ({
//       ...item,
//       sno: start + index + 1,
//     }));
//     setDisplayedData(paginatedData);
//   }, [activePage, filterDocumentList]);

//   useEffect(() => {
//     if (searchString) {
//       const result = documentList?.filter((rowObj) => {
//         return (
//           rowObj?.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
//           rowObj?.employeename.toLowerCase().includes(searchString.toLowerCase())
//         );
//       });
//       setFilterDocumentList(result);
//     } else {
//       setFilterDocumentList(documentList);
//     }
//   }, [documentList, searchString]);

//   const handleCreateDocumentClick = () => {
//     setOpenModalType("EmployeeDetail");
//   };

//   const closeModal = () => {
//     setOpenModalType(null);
//     setPageRefresh(true);
//   };
//   const downloadDocumentFile = (data) => {
//     setLoading(true);
//     const payload = {
//       id: data?.empid?._id,
//       document: data?.document,
//     };
//     apiManager
//       .downloadDocumnet(payload)
//       .then((response) => {
//         let filename = data?.document + ".pdf";
//         console.log(response.data, "response.data");
//         const url = URL.createObjectURL(response?.data);
//         console.log(url, "urlDocument");
//         let aTag = document?.createElement("a");
//         aTag.href = url;
//         aTag.download = filename;
//         document.body.appendChild(aTag);
//         aTag.click();
//         document.body.removeChild(aTag);
//         setmsgType("success");
//         setShowMSG(response?.data?.message || "details updated successfully");
//         closeModal();
//       })
//       .catch((errors) => {
//         setmsgType("error");
//         setShowMSG(errors?.response?.data?.message || "failed to update");
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   const customHtml = (data) => {
//     return <Button onClick={() => handleView(data)} label="View" type="button" />;
//   };
//   const customHtml1 = (data) => {
//     console.log(data, "data");
//     return (
//       <>
//         {data.document_status === "pending" ? (
//           <Button type="button" label="Download" disabled={true} />
//         ) : (
//           <Button type="button" label="Download" onClick={() => downloadDocumentFile(data)} />
//         )}
//       </>
//     );
//   };
//   const customHtml2 = (data) => (
//     <div className="d-flex gap-3">
//       {customHtml(data)}
//       {customHtml1(data)}
//     </div>
//   );

//   const columns = [
//     { header: "S.No", field: "" },
//     { header: "Employee Id", field: "employeeID" },
//     { header: "Employee Name", field: "employeename" },
//     { header: "Email ID", field: "company_email" },
//     { header: "Department", field: "department" },
//     { header: "Designation", field: "designation" },
//     { header: "Generate Document", field: "document" },
//     {
//       header: "Status",
//       field: "document_status",
//       customHtml: (data) => {
//         const statusColor = data.document_status === "approved" ? "green" : "blue";
//         return <span style={{ color: statusColor }}>{data.document_status}</span>;
//       },
//     },

//     { header: "", field: "actions", customHtml: customHtml2 },
//   ];
//   const handleView = (data) => {
//     setSelectedItem(data);
//     setOpenModalType("LettersView");
//   };
//   const getDocumentList = () => {
//     setLoading(true);
//     apiManager
//       .ApprovedList()
//       .then((res) => {
//         setDocumentList(res?.data.approvedDocuments);
//       })
//       .catch((error) => {
//         console.log(error, "error");
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   useEffect(() => {
//     getDocumentList();
//   }, [pageRefresh]);
//   return (
//     <div>
//       <SubHeader location={useLocation()} />
//       <div className="container-fluid">
//         <div className="row m-0 p-0">
//           <div className="col-lg-4 mt-4">
//             <SearchFilter
//               data={searchString}
//               setSearchString={setSearchString}
//               placeholder={"Search Employee By Name/ID"}
//             />
//           </div>
//           <div className="col d-flex justify-content-end mt-4">
//             <Button label="Create Document" onClick={handleCreateDocumentClick} />
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-lg-12 px-2 pt-3 tableContainer">
//             <Table columns={columns} Data={displayedData} className="pointer" LeaveTableHeight="LeaveTableHeight" />
//           </div>
//           <div className="pt-4 d-flex justify-content-end">
//             <Pagination
//               prev
//               last
//               next
//               first
//               size="sm"
//               total={filterDocumentList.length}
//               limit={itemsPerPage}
//               activePage={activePage}
//               onChangePage={setActivePage}
//             />
//           </div>
//         </div>
//         <CommonModal
//           open={openModalType === "EmployeeDetail"}
//           onClose={closeModal}
//           size="sm"
//           title={"Employee Details"}
//         >
//           <EmployeeDetail />
//         </CommonModal>
//         <CommonModal open={openModalType === "LettersView"} onClose={closeModal} size="lg" title={"Letters View"}>
//           <GenerateLetter selectedItem={selectedItem} onClose={closeModal} />
//         </CommonModal>
//       </div>
//       <CoreLoader show={loading} />
//     </div>
//   );
// };

// export default GenerateDocuments;

import { SearchFilter } from "Components/AttendaceReport";
import GenerateLetter from "Components/GenerateDocuments/GenerateLetter";
import { SubHeader, Table } from "UI_Components";
import { Button, CommonModal, CoreLoader } from "common/components";
import EmployeeDetail from "common/components/EmployeeDetail";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "rsuite";

const GenerateDocuments = () => {
  const [searchString, setSearchString] = useState("");
  const [openModalType, setOpenModalType] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [filterDocumentList, setFilterDocumentList] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [pageRefresh, setPageRefresh] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  // Update pagination and filtered data
  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filterDocumentList.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filterDocumentList]);

  // Update filterDocumentList based on searchString
  useEffect(() => {
    if (searchString) {
      const result = documentList?.filter((rowObj) => {
        return (
          rowObj?.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj?.employeename.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      setFilterDocumentList(result);
      setActivePage(1); // Reset to page 1 when search changes
    } else {
      setFilterDocumentList(documentList);
      setActivePage(1); // Reset to page 1 when search is cleared
    }
  }, [documentList, searchString]);

  const handleCreateDocumentClick = () => {
    setOpenModalType("EmployeeDetail");
  };

  const closeModal = () => {
    setOpenModalType(null);
    setPageRefresh(true);
  };

  const downloadDocumentFile = (data) => {
    setLoading(true);
    const payload = {
      id: data?.empid?._id,
      document: data?.document,
    };
    apiManager
      .downloadDocumnet(payload)
      .then((response) => {
        let filename = data?.document + ".pdf";
        console.log(response.data, "response.data");
        const url = URL.createObjectURL(response?.data);
        console.log(url, "urlDocument");
        let aTag = document?.createElement("a");
        aTag.href = url;
        aTag.download = filename;
        document.body.appendChild(aTag);
        aTag.click();
        document.body.removeChild(aTag);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        closeModal();
      })
      .catch((errors) => {
        setmsgType("error");
        setShowMSG(errors?.response?.data?.message || "failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const customHtml = (data) => {
    return <Button onClick={() => handleView(data)} label="View" type="button" />;
  };

  const customHtml1 = (data) => {
    console.log(data, "data");
    return (
      <>
        {data.document_status === "pending" ? (
          <Button type="button" label="Download" disabled={true} />
        ) : (
          <Button type="button" label="Download" onClick={() => downloadDocumentFile(data)} />
        )}
      </>
    );
  };

  const customHtml2 = (data) => (
    <div className="d-flex gap-3">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );

  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "employeename" },
    { header: "Email ID", field: "company_email" },
    { header: "Department", field: "department" },
    { header: "Designation", field: "designation" },
    { header: "Generate Document", field: "document" },
    {
      header: "Status",
      field: "document_status",
      customHtml: (data) => {
        const statusColor = data.document_status === "approved" ? "green" : "blue";
        return <span style={{ color: statusColor }}>{data.document_status}</span>;
      },
    },
    { header: "", field: "actions", customHtml: customHtml2 },
  ];

  const handleView = (data) => {
    setSelectedItem(data);
    setOpenModalType("LettersView");
  };

  const getDocumentList = () => {
    setLoading(true);
    apiManager
      .ApprovedList()
      .then((res) => {
        setDocumentList(res?.data.approvedDocuments);
        setFilterDocumentList(res?.data.approvedDocuments); // Set initial filtered list
      })
      .catch((error) => {
        console.log(error, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentList();
  }, [pageRefresh]);

  return (
    <div>
      <SubHeader location={useLocation()} />
      <div className="container-fluid">
        <div className="row m-0 p-0">
          <div className="col-lg-4 mt-4">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Search Employee By Name/ID"}
            />
          </div>
          <div className="col d-flex justify-content-end mt-4">
            <Button label="Create Document" onClick={handleCreateDocumentClick} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 px-2 pt-3 tableContainer">
            <Table columns={columns} Data={displayedData} className="pointer" LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={filterDocumentList.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
        <CommonModal
          open={openModalType === "EmployeeDetail"}
          onClose={closeModal}
          size="sm"
          title={"Employee Details"}
        >
          <EmployeeDetail />
        </CommonModal>
        <CommonModal open={openModalType === "LettersView"} onClose={closeModal} size="lg" title={"Letters View"}>
          <GenerateLetter selectedItem={selectedItem} onClose={closeModal} />
        </CommonModal>
      </div>
      <CoreLoader show={loading} />
    </div>
  );
};

export default GenerateDocuments;
