import React, { useEffect, useRef, useState } from "react";
import uploadImage from "../../assets/images/Vector (11).png";
import apiManager from "networking/baseAPIManager";
import Button from "./Button";
import CoreLoader from "./Loader";

export default function BulkAssetModal({ setmsgType, setShowMSG, setOpen }) {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  console.log(selectedFile, "selectedFile");
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const bulkAssetsDetail = () => {
    let formdata = new FormData();
    formdata.append("excel", selectedFile);
    setLoading(true);
    apiManager
      .bulkAsset(formdata)
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "File uploaded successfully");
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "Failed to file uploaded");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="container pt-3">
        <div className="row px-5 text-center">
          <div
            className="pt-3 m-auto"
            style={{
              border: "solid 1px",
              borderRadius: "6px",
              height: "180px",
              width: "50%",
            }}
            onClick={handleUploadClick}
          >
            <div className=" ">
              <img src={uploadImage} alt="File Upload Icon" />
            </div>
            <div className="pt-5">Browse File To Upload</div>
          </div>
          <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileSelect} />
          {selectedFile && <div className="pt-3">Selected File: {selectedFile.name}</div>}
          <div className="pt-5 text-align-center justify-content-center d-flex">
            <Button label="Send" onClick={() => bulkAssetsDetail()} />
          </div>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
}
