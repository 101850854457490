import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader, DownloadIcon, Dropdown, Input, Label } from "common/components";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { AssatSchema } from "utils/formValidationUtils";
function UpdateAssets() {
  const [uploadedImage, setUploadedImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [showFileInput, setShowFileInput] = useState(false);
  const [formData, setFormData] = useState({});
  const [assetNameTypes, setAssetNameTypes] = useState([]);
  const [assetStatusTypes, setAssetStatusTypes] = useState([]);
  const [manufacturerTypes, setManufacturerTypes] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  let paramsId = params.id;
  const formInitialValues = formData;

  useEffect(() => {
    Promise.all([getAssetNameTypes(), getAssetStatusTypes(), getManufacturerTypes()])
      .then((results) => {
        const assetNameTypesResponse = results[0];
        const assetStatusTypesResponse = results[1];
        const manufacturerTypesResponse = results[2];

        setAssetNameTypes(assetNameTypesResponse?.data?.data);
        setAssetStatusTypes(assetStatusTypesResponse?.data?.data);
        setManufacturerTypes(manufacturerTypesResponse?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getAssetNameTypes = () => {
    return apiManager.getDropdownTypes("assetName");
  };

  const getAssetStatusTypes = () => {
    return apiManager.getDropdownTypes("assetStatus");
  };

  const getManufacturerTypes = () => {
    return apiManager.getDropdownTypes("manufacturer");
  };

  const getAssetDetail = () => {
    setLoading(true);
    apiManager
      .getAsset(paramsId)
      .then((res) => {
        setUploadedImage(res?.data?.Asset?.image);
        setFormData({
          assetName: res?.data?.Asset?.assetname || "",
          purchaseDate: res?.data?.Asset?.purchase_date || "",
          manufacturer: res?.data?.Asset?.manufacturer || "",
          serialNumber: res?.data?.Asset?.serial_number || "",
          assetValue: res?.data?.Asset?.value || "",
          documentUpload: res?.data?.Asset?.image[0] || "null",
          assetId: res?.data?.Asset?.assetId || "",
          purchaseFrom: res?.data?.Asset?.purchase_from || "",
          model: res?.data?.Asset?.model || "",
          supplier: res?.data?.Asset?.supplier || "",
          warranty: res?.data?.Asset?.warranty || "",
          description: res?.data?.Asset?.description || "",
          status: res?.data?.Asset?.status || "",
        });
      })
      .catch((error) => {
        console.warn(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (paramsId) getAssetDetail();
  }, [paramsId]);

  const { handleBlur, handleChange, handleSubmit, setFieldValue, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: formInitialValues,
    validationSchema: AssatSchema,

    onSubmit: (values) => {
      let formdata = new FormData();
      formdata.append("id", paramsId);
      formdata.append("assetname", values.assetName);
      formdata.append("assetId", values.assetId);
      formdata.append("purchase_date", values.purchaseDate);
      formdata.append("purchase_from", values.purchaseFrom);
      formdata.append("manufacturer", values.manufacturer);
      formdata.append("model", values.model);
      formdata.append("serial_number", values.serialNumber);
      formdata.append("supplier", values.supplier);
      formdata.append("warranty", values.warranty);
      formdata.append("value", values.assetValue);
      formdata.append("status", values.status);
      formdata.append("image", values.documentUpload);
      formdata.append("description", values.description);

      console.log(formdata, "formdata");
      setLoading(true);
      apiManager
        .updateAsset(formdata)
        .then((res) => {
          setLoading(false);
          setmsgType("success");
          setShowMSG(res?.data?.message || "Asset updated successfully");
          navigate("/hrtools/assets-management/pending-asset-verification");
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.data?.message || "failed to updated asset");
        });
    },
  });

  const renderFileInput = (name) => {
    const fileUrl =
      values[name] && typeof values[name] === "string" ? `${process.env.REACT_APP_BASE_URL}/${values[name]}` : null;

    if (fileUrl) {
      const downloadAttachment = async () => {
        try {
          const response = await fetch(fileUrl);
          if (!response.ok) throw new Error("Network response was not ok");
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          const fileName = fileUrl.split("/").pop();
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      };

      return (
        <div className="w-50 d-flex gap-3 align-items-center my-auto">
          <Button onClick={downloadAttachment} label="Download" type="button" />
          <span className="material-symbols-outlined pointer" onClick={() => setFieldValue(name, undefined)}>
            edit
          </span>
        </div>
      );
    }

    return (
      <Input
        type="file"
        name={name}
        onBlur={handleBlur}
        onChange={(e) => {
          setFieldValue(name, e.target.files[0]);
        }}
        isValid={touched[name] && !errors[name]}
        touched={touched[name]}
        error={errors[name]}
      />
    );
  };
  return (
    <>
      <div>
        <SubHeader location={useLocation()} />

        <div className="col-lg-12 px-5 ">
          <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
          <div className="col-lg-12 pt-4 form-main ">
            <div className="gap-3 text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
              <h2>Update Asset</h2>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="container pb-5">
                <div className="form-container">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-md-5 mt-4 ms-2">
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Asset Name" required />

                        <Dropdown
                          name="assetName"
                          value={values.assetName}
                          options={assetNameTypes}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.assetName}
                          touched={touched.assetName}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Purchase Date" required />
                        <Input
                          type="date"
                          name="purchaseDate"
                          value={values.purchaseDate}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.purchaseDate}
                          touched={touched.purchaseDate}
                          min="1950-01-01"
                          max="2999-12-31"
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Manufacturer " required />
                        <Dropdown
                          name="manufacturer"
                          value={values.manufacturer}
                          options={manufacturerTypes}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.manufacturer}
                          touched={touched.manufacturer}
                          maxlength={50}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Serial Number" required />
                        <Input
                          name="serialNumber"
                          value={values.serialNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.serialNumber}
                          touched={touched.serialNumber}
                          maxlength={20}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Asset Value" required />
                        <Input
                          name="assetValue"
                          value={values.assetValue}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.assetValue}
                          touched={touched.assetValue}
                          maxlength={10}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Status" required />
                        <Dropdown
                          name="status"
                          value={values.status}
                          options={assetStatusTypes}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.status}
                          touched={touched.status}
                        />
                      </div>
                    </div>
                    <div className="col-md-5  mt-4 ms-2">
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Asset Id" required />
                        <Input
                          name="assetId"
                          value={values.assetId}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.assetId}
                          touched={touched.assetId}
                          maxlength={20}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Purchase From" required />
                        <Input
                          name="purchaseFrom"
                          type="text"
                          value={values.purchaseFrom}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.purchaseFrom}
                          touched={touched.purchaseFrom}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Model" required />
                        <Input
                          name="model"
                          value={values.model}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.model}
                          touched={touched.model}
                          maxlength={20}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Supplier" required />
                        <Dropdown
                          name="supplier"
                          options={manufacturerTypes}
                          value={values.supplier}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.supplier}
                          touched={touched.supplier}
                          maxlength={50}
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Warranty" required />
                        <Input
                          name="warranty"
                          type="date"
                          value={values.warranty}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.warranty}
                          touched={touched.warranty}
                          min="1950-01-01"
                          max="2999-12-31"
                        />
                      </div>
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Document Upload" />
                        {renderFileInput("documentUpload")}
                      </div>
                    </div>
                    <div className="col-md-10 ms-2 me-2 whi">
                      <div className=" form-outline mb-4 row align-items-center ms-0">
                        <Label label="Description" />
                        <Input
                          name="description"
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={errors.description}
                          touched={touched.description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" col-lg-12 d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
                <Button
                  className="btn btn-primary sub-btn"
                  variant="primary"
                  type="submit"
                  label="Update"
                  // onClick={handleUpdateForm}
                />
                <Button
                  className="btn btn-primary sub-btn"
                  variant="primary"
                  type="close"
                  label="Close"
                  onClick={() => navigate("/hrtools/assets-management/pending-asset-verification")}
                />
              </div>
            </form>
          </div>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
}

export default UpdateAssets;
