import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CoreLoader, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export const AddHolidayListCalendar = () => {
  const [loading, setLoading] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setMsgType] = useState("info");
  const [msgContent, setMsgContent] = useState("");
  const { id } = useParams();

  const fetchHolidays = () => {
    setLoading(true);
    let params = {
      id: id,
    };

    apiManager
      .holidaypending(params)
      .then((res) => {
        console.log(res, "response=====================");
        setHolidays(res.data.status.holiday);
      })
      .catch((error) => {
        console.error("Error fetching holiday list:", error);
        setMsgType("error");
        setMsgContent(error?.response?.data?.message || "Failed to update");
        setShowMSG(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHolidays();
  }, [id]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedHolidays = [...holidays];
    if (name === "holiday_name") {
      const regex = /^[A-Za-z ]*$/;
      if (!regex.test(value)) {
        return;
      }
    }
    updatedHolidays[index][name] = value;
    setHolidays(updatedHolidays);
  };

  const handleApproveReject = async (action = false) => {
    setLoading(true);
    let params = {
      id: id,
      holiday_status: action ? "approved" : "rejected",
    };

    apiManager
      .holidayapprovied(params)
      .then((response) => {
        console.log(response?.data?.message);
        setMsgType("success");
        setMsgContent(response?.data?.message || "Updated successfully");
        setShowMSG(true);
        const updatedHolidays = holidays.filter((holiday) => holiday.id !== params.id);
        setHolidays(updatedHolidays);
      })
      .catch((error) => {
        console.error("Error approving/rejecting holiday:", error);
        setMsgType("error");
        setMsgContent(error?.response?.data?.message || "Failed to update");
        setShowMSG(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="Container">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} title={msgContent} />
        <SubHeader location={useLocation()} />
        <div className="d-flex justify-content-between">
          <div className="row p-3 g-3 mb-3 border rounded m-5 main_table">
            {Array.isArray(holidays) &&
              holidays.map((holiday, index) => (
                <div key={index} className="col-md-6 p-3 mb-3 border rounded">
                  <Label label="Holiday Name" parentclasses="col-lg-6 col-xxl-4" />
                  <Input
                    type="text"
                    name="holiday_name"
                    inputclasses="form-controlcustom w-100"
                    parentclasses="col-lg-12 col-xxl-8"
                    value={holiday.holiday_name}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                  <Label label="Date" parentclasses="col-lg-6 col-xxl-4 mt-4" />
                  <Input
                    type="date"
                    name="date"
                    inputclasses="form-controlcustom w-100"
                    parentclasses="col-lg-12 col-xxl-8 mt-4"
                    value={holiday.date}
                    onChange={(event) => handleInputChange(index, event)}
                  />
                </div>
              ))}

            <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4 col-md-12">
              <Button type="button" label="Approve" onClick={() => handleApproveReject(true)} />
              <Button type="button" label="Reject" onClick={() => handleApproveReject(false)} />
            </div>
          </div>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </div>
  );
};
