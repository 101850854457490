import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../../assets/style/leaverequest.css";
import Tabs from "../../../../UI_Components/Tabs/index";
import SubHeader from "../../../../UI_Components/SubHeader";
import dummyData from "../../../../dummyData/onboarding.json";
import { convertDateFormat, formateDate } from "../../../../utils/dateFunctions";

import { CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import CreateRequest from "Components/LeaveRequest/CreateRequest";
import LeavePending from "Components/LeaveRequest/LeavePending";
import LeaveComplete from "Components/LeaveRequest/LeaveComplete";
import LeaveApproval from "Components/LeaveRequest/LeaveApproval";

const LeaveRequest = () => {
  const dataToShow = [];
  const [leaveApiData, setLeaveApiData] = useState([]);
  const [loading, setLoading] = useState(false);

  const { inprocessdata, completeddata, pendingdata } = dummyData;
  console.log(leaveApiData, "leaveApiData");
  useEffect(() => {
    getLeaveList();
  }, []);

  let getLeaveList = () => {
    setLoading(true);
    apiManager
      .LeaveList()
      .then((response) => {
        setLoading(false);
        setLeaveApiData(response.data.leave);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  let completedData = leaveApiData.filter((data) => {
    const lowercaseStatus = String(data.status).toLowerCase();
    return ["approved", "rejected", "cancelled"].includes(lowercaseStatus);
  });
  let pendingData = leaveApiData.filter((data) => String(data.status).toLowerCase() === "pending");
  let approvalData = pendingData;

  let arrData = [
    { title: "Create Request", content: <CreateRequest fetchapi={getLeaveList} /> },
    { title: "Pending", content: <LeavePending fetchapi={getLeaveList} leavePendingApiData={pendingData} /> },
    { title: "Complete", content: <LeaveComplete fetchapi={getLeaveList} completeData={completedData} /> },
    { title: "Approval Request", content: <LeaveApproval fetchapi={getLeaveList} approvalData={approvalData} /> },
  ];
  const [tabs, setTabs] = useState(arrData);

  useEffect(() => {
    setTabs([...arrData]);
  }, [leaveApiData]);
  return (
    <div>
      <SubHeader location={useLocation()} />
      <div className="row m-0 p-0">
        <div className="col-lg-12 ">
          <Tabs tabs={tabs} />
        </div>
      </div>
      <CoreLoader show={loading} />
    </div>
  );
};

export default LeaveRequest;
