// import { SearchFilter } from "Components/AttendaceReport";
// import { Table } from "UI_Components";
// import { AlertMessage, Button, CoreLoader, Dropdown, Input, Label, Textarea } from "common/components";
// import apiManager from "networking/baseAPIManager";
// import React, { useEffect, useState } from "react";

// export default function AdvanceSalaryRequest({ fetchapi }) {
//   const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
//   const [selectType, setSelectType] = useState("");
//   const [amount, setAmount] = useState("");
//   const [installment, setInstallment] = useState("");
//   const [emiAmount, setEmiAmount] = useState("");
//   const [selectReason, setReason] = useState("");
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [showMSG, setShowMSG] = useState(false);
//   const [msgType, setmsgType] = useState("info");
//   const [showInput, setShowInput] = useState(false);

//   const calculateEmiAmount = () => {
//     if (amount && installment) {
//       const totalAmount = parseFloat(amount);
//       const totalInstallment = parseInt(installment);
//       const emi = totalAmount / totalInstallment;
//       setEmiAmount(emi.toFixed(2));
//     }
//   };

//   useEffect(() => {
//     calculateEmiAmount();
//   }, [amount, installment]);

//   const getAdvanceSalaryType = () => {
//     setLoading(true);
//     apiManager
//       .getDropdownTypes("Advance_Salary_Type")
//       .then((response) => {
//         setSalaryTypeOptions(response?.data?.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching leave type options:", error);
//       })
//       .finally(() => {
//         setLoading(false);
//       });
//   };
//   const installmentType = [
//     { type: "installment", id: " 3 ", name: "3 Month" },
//     { type: "installment", id: " 6 ", name: "6 Month" },
//     { type: "installment", id: " 9 ", name: "9 Month" },
//   ];
//   const handleAdvanceSalarySubmit = (e) => {
//     e.preventDefault();
//     let formdata = new FormData();
//     formdata.append("advance_salary_type", selectType);
//     formdata.append("amount", amount);
//     formdata.append("instalment", installment);
//     formdata.append("emi_amount", emiAmount);
//     formdata.append("reason", selectReason);
//     formdata.append("attachment", selectedFile);
//     apiManager
//       .createAdvanceSalary(formdata)
//       .then((response) => {
//         setmsgType("success");
//         setShowMSG(response?.data?.message);
//         fetchapi && fetchapi();
//         resetFormFields();
//       })
//       .catch((error) => {
//         console.log(error, "error");
//         setmsgType("error");
//         setShowMSG(error?.response?.data?.message);
//       });
//   };
//   const resetFormFields = () => {
//     setSelectType("");
//     setAmount("");
//     setInstallment("");
//     setEmiAmount("");
//     setReason("");
//     setSelectedFile(null);
//     const fileInput = document.getElementById("attachment");
//     if (fileInput) {
//       fileInput.value = "";
//     }
//   };
//   useEffect(() => {
//     getAdvanceSalaryType();
//   }, []);
//   return (
//     <>
//       <div className="col-lg-12 px-5 pt-5">
//         <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
//         <div className=" pt-5 form-main ">
//           <div className="row pb-5">
//             <div className="form-container container">
//               <div className="col-lg-8 m-auto">
//                 <form className="leave-request">
//                   <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="Advance Salary Type"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center ps-5 ms-3 "
//                     />
//                     <Dropdown
//                       parentclasses="col-xl-7 col-md-6 col-sm-11 "
//                       value={selectType}
//                       onChange={(e) => {
//                         setSelectType(e.target.value);
//                       }}
//                       options={salaryTypeOptions}
//                       placeholder="Select Leave Types"
//                       sidelabel
//                     />
//                   </div>
//                   <div className=" col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="Amount"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-5"
//                     />
//                     <Input
//                       parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3 "
//                       inputclasses="form-controlcustom"
//                       type="text"
//                       name="amount"
//                       value={amount}
//                       onChange={(e) => {
//                         setAmount(e.target.value);
//                       }}
//                     />
//                   </div>
//                   <div className=" col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="Installment"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-4 "
//                     />
//                     <Dropdown
//                       parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
//                       inputclasses="form-controlcustom"
//                       name="installment"
//                       options={installmentType}
//                       value={installment}
//                       onChange={(e) => {
//                         setInstallment(e.target.value);
//                       }}
//                     />
//                   </div>
//                   <div className=" col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="EMI Amount"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-3 "
//                     />
//                     <Input
//                       parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3 "
//                       inputclasses="form-controlcustom"
//                       name="emiAmount"
//                       value={emiAmount}
//                       onChange={(e) => {
//                         setEmiAmount(e.target.value);
//                       }}
//                       disabled
//                     />
//                   </div>
//                   <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="Reason"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-5"
//                     />
//                     <Textarea
//                       parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3 "
//                       name="reason"
//                       value={selectReason}
//                       onChange={(e) => {
//                         setReason(e.target.value);
//                       }}
//                     />
//                   </div>
//                   <div className=" col-lg-12 form-outline mb-4 row align-items-center ms-0">
//                     <Label
//                       label="Attachment"
//                       parentclasses="col-xl-4 col-md-5 col-sm-12  d-flex justify-content-center pe-3"
//                     />
//                     <Input
//                       parentclasses="col-xl-7 col-md-6 col-sm-11  ms-3 "
//                       inputclasses="form-controlcustom"
//                       type="file"
//                       id="attachment"
//                       name="attachment"
//                       onChange={(e) => {
//                         setSelectedFile(e.target.files[0]);
//                       }}
//                       sidelabel
//                     />
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//           <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
//             <Button
//               onClick={(e) => handleAdvanceSalarySubmit(e)}
//               className="btn btn-primary sub-btn"
//               variant="primary"
//               type="submit"
//               label="Submit"
//             />
//           </div>
//         </div>
//       </div>
//       <CoreLoader show={loading} disableLines />
//     </>
//   );
// }
import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import { SearchFilter } from "Components/AttendaceReport";
import { Table } from "UI_Components";
import { advanceSalarySchema } from "utils/formValidationUtils";
import { AlertMessage, Button, CoreLoader, Dropdown, Input, Label, Textarea } from "common/components";
import apiManager from "networking/baseAPIManager";

const AdvanceSalaryRequest = ({ fetchapi }) => {
  const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  // Fetch Advance Salary Types
  const getAdvanceSalaryType = useCallback(() => {
    setLoading(true);
    apiManager
      .getDropdownTypes("Advance_Salary_Type")
      .then((response) => {
        setSalaryTypeOptions(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching salary type options:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getAdvanceSalaryType();
  }, [getAdvanceSalaryType]);

  // Formik setup
  const formik = useFormik({
    initialValues: {
      selectType: "",
      amount: "",
      installment: "",
      selectReason: "",
      selectedFile: null,
    },
    validationSchema: advanceSalarySchema,
    onSubmit: (values, { resetForm }) => {
      const formdata = new FormData();
      formdata.append("advance_salary_type", values.selectType);
      formdata.append("amount", values.amount);
      formdata.append("instalment", values.installment);
      formdata.append("emi_amount", (values.amount / parseInt(values.installment)).toFixed(2));
      formdata.append("reason", values.selectReason);
      formdata.append("attachment", values.selectedFile);

      apiManager
        .createAdvanceSalary(formdata)
        .then((response) => {
          setmsgType("success");
          setShowMSG(response?.data?.message);
          fetchapi && fetchapi();
          resetForm();
        })
        .catch((error) => {
          console.error("Error submitting advance salary request:", error);
          setmsgType("error");
          setShowMSG(error?.response?.data?.message);
        });
    },
  });

  const { handleBlur, handleChange, handleSubmit, setFieldValue, errors, values, touched } = formik;
  console.log(errors, "reason");
  return (
    <>
      <div className="col-lg-12 px-5 pt-5">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="pt-5 form-main">
          <div className="row pb-5">
            <div className="form-container container">
              <div className="col-lg-8 m-auto">
                <form className="leave-request" onSubmit={handleSubmit}>
                  {/* Advance Salary Type */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="Advance Salary Type"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center ps-5 ms-3"
                      required
                    />
                    <Dropdown
                      parentclasses="col-xl-7 col-md-6 col-sm-11"
                      value={values.selectType}
                      onChange={(e) => setFieldValue("selectType", e.target.value)}
                      options={salaryTypeOptions}
                      placeholder="Select Leave Types"
                    />
                  </div>

                  {/* Amount */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="Amount"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-5"
                      required
                    />
                    <Input
                      parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
                      inputclasses="form-controlcustom"
                      type="text"
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.amount && !errors.amount}
                      touched={touched.amount}
                      error={errors.amount}
                      maxLength={9}
                      minLength={4}
                    />
                  </div>

                  {/* Installment */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="Installment"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-4"
                      required
                    />
                    <Dropdown
                      parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
                      inputclasses="form-controlcustom"
                      name="installment"
                      options={[
                        { type: "installment", id: "3", name: "3 Month" },
                        { type: "installment", id: "6", name: "6 Month" },
                        { type: "installment", id: "9", name: "9 Month" },
                      ]}
                      value={values.installment}
                      onChange={(e) => setFieldValue("installment", e.target.value)}
                    />
                  </div>

                  {/* EMI Amount */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="EMI Amount"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-3"
                      required
                    />
                    <Input
                      parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
                      inputclasses="form-controlcustom"
                      name="emiAmount"
                      value={(values.amount / parseInt(values.installment)).toFixed(2)}
                      disabled
                    />
                  </div>

                  {/* Reason */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="Reason"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-5"
                      required
                    />
                    <Textarea
                      parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
                      name="selectReason"
                      value={values.selectReason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.selectReason && !errors.selectReason}
                      touched={touched.selectReason}
                      error={errors.selectReason}
                      maxLength={250}
                    />
                  </div>

                  {/* Attachment */}
                  <div className="col-lg-12 form-outline mb-4 row align-items-center ms-0">
                    <Label
                      label="Attachment"
                      parentclasses="col-xl-4 col-md-5 col-sm-12 d-flex justify-content-center pe-3"
                    />
                    <Input
                      parentclasses="col-xl-7 col-md-6 col-sm-11 ms-3"
                      inputclasses="form-controlcustom"
                      type="file"
                      id="attachment"
                      name="selectedFile"
                      onChange={(e) => setFieldValue("selectedFile", e.target.files[0])}
                      onBlur={handleBlur}
                      isValid={touched.selectedFile && !errors.selectedFile}
                      touched={touched.selectedFile}
                      error={errors.selectedFile}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
            <Button
              onClick={handleSubmit}
              className="btn btn-primary sub-btn"
              variant="primary"
              type="submit"
              label="Submit"
            />
          </div>
        </div>
      </div>

      {/* Loader */}
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default AdvanceSalaryRequest;
