import { SearchFilter } from "Components/AttendaceReport";
import { SubHeader, Table } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import ManageFundView from "./ManageFundView";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Pagination } from "rsuite";
const EditSalary = () => {
  const [searchString, setSearchString] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [payrollDataList, setPayrollDataList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewButtonClicked, setViewButtonClicked] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");

  const navigate = useNavigate();
  const location = useLocation();

  const selectedID = selectedRows.map((data) => data._id);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  };
  const getMonthOptions = () => {
    return moment.months().map((month, index) => ({
      value: month,
      label: month,
    }));
  };

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = moment().format("MMMM");
    setSelectedYear(currentYear);
    setSelectedMonth(currentMonth);
  }, []);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  useEffect(() => {
    payrollApprovedList();
  }, [!isOpen, selectedMonth, selectedYear]);

  const payrollApprovedList = () => {
    const monthNumber = moment().month(selectedMonth).format("M");
    setLoading(true);
    apiManager
      .salaryList({ month: monthNumber, year: selectedYear })
      .then((response) => {
        setLoading(false);
        setPayrollDataList(response?.data?.SalaryList || []);
        setSelectedRows([]);
        setSelectAll(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleItemClick = (data) => {
    setIsOpen(true);
    setViewData(data);
    setViewButtonClicked(true);
  };

  const handleSendToApprove = () => {
    setLoading(true);
    const id = { id: selectedID };
    apiManager
      .approvedSalary(id)
      .then((response) => {
        console.log(response, "ApprovedResponse");
        setLoading(false);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        payrollApprovedList();
      })
      .catch((error) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "failed to update");
        console.error(error);
      });
  };

  const handleCheckboxChange = (event, data) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedRows((prev) => [...prev, data]);
    } else {
      setSelectedRows((prev) => prev.filter((row) => row.employeeID !== data.employeeID));
    }
  };

  const handleSelectAllChange = (event) => {
    const { checked } = event.target;
    setSelectAll(checked);
    if (checked) {
      setSelectedRows(payrollDataList);
    } else {
      setSelectedRows([]);
    }
  };

  const customHtml = (data) => {
    return data.salary_status === "pending" ? (
      <Button
        onClick={() => {
          console.log(data, "data");
          navigate("/hrtools/manage-fund/edit-salary/form", { state: { data } });
        }}
        label="Edit"
      />
    ) : (
      <Button onClick={() => handleItemClick(data)} label="View" />
    );
  };

  const columns = [
    {
      header: <input type="checkbox" checked={selectAll} onChange={handleSelectAllChange} />,
      field: "checkbox",
      customHtml: (data) => (
        <input
          type="checkbox"
          checked={selectedRows.some((row) => row.employeeID === data.employeeID)}
          onChange={(e) => handleCheckboxChange(e, data)}
        />
      ),
    },
    { header: "S.No", field: "" },
    { header: "Employee Name", field: "empname" },
    { header: "Employee ID", field: "employeeID" },
    { header: "Present Days", field: "presentDay" },
    { header: "Total Leave", field: "totalLeave" },
    {
      header: "Net Pays",
      field: "netSalary",

      customHtml: (data) => {
        const netPay = data.netSalary ? data.netSalary.toFixed(2) : "0.00";

        return <span>{netPay}</span>;
      },
    },
    {
      header: "Status",
      field: "salary_status",
      customHtml: (data) => {
        const statusColor = data.salary_status === "pending" ? "red" : data.salary_status === "hold" ? "blue" : "green";
        return <span style={{ color: statusColor }}>{data.salary_status}</span>;
      },
    },
    { header: "", field: "actions", btnType: "primary", customHtml },
  ];

  useEffect(() => {
    updateDisplayedData();
  }, [payrollDataList, activePage, searchString]);

  const updateDisplayedData = () => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = payrollDataList.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));

    let filteredData = paginatedData.filter(
      (item) =>
        item.empname.toLowerCase().includes(searchString.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(searchString.toLowerCase())
    );

    setDisplayedData(filteredData);
  };
  return (
    <>
      <div>
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        <div className="container-fluid">
          <SubHeader location={location} />
          <div className="row p-3">
            <div className="col-lg-4 px-2">
              <SearchFilter
                data={searchString}
                setSearchString={setSearchString}
                placeholder="Search Employee By Name/ID"
              />
            </div>

            <div className="col-lg-8 d-flex justify-content-end gap-3">
              <div>
                <Dropdown
                  parentclasses=""
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  options={getMonthOptions()}
                  placeholder="Month"
                  sidelabel
                />
              </div>
              <div>
                <Dropdown
                  parentclasses=""
                  value={selectedYear}
                  onChange={handleYearChange}
                  options={getYearOptions()}
                  placeholder="Year"
                  sidelabel
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 px-2 tableContainer">
              <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
              <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 mt-3 px-4">
                <Button label="Send To Approve" onClick={handleSendToApprove} />
              </div>
            </div>
            <div className="pt-5 d-flex justify-content-end">
              <Pagination
                prev
                last
                next
                first
                size="sm"
                total={payrollDataList.length}
                limit={itemsPerPage}
                activePage={activePage}
                onChangePage={setActivePage}
              />
            </div>
          </div>
          <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="lg" title="Employee Info">
            <ManageFundView
              data={viewData}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              showPayHoldButton={viewButtonClicked}
            />
          </CommonModal>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </>
  );
};

export default EditSalary;
