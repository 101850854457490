import { AlertMessage, Button, CoreLoader, Input } from "common/components";
import { useFormik } from "formik";
import * as yup from "yup";

import React from "react";
import apiManager from "networking/baseAPIManager";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AssetAssign = ({ assetObjectId, setOpen, setShowMSG, setmsgType }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { handleChange, handleBlur, handleSubmit, touched, values, errors } = useFormik({
    initialValues: {
      email_id: "",
    },
    validationSchema: yup.object().shape({
      email_id: yup.string().email("Please enter a valid email address").required("Email is required"),
    }),
    onSubmit: (values) => {
      let AssignDetail = {
        assetObject: `${assetObjectId}`,
        email_id: `${values.email_id}`,
      };
      setLoading(true);
      apiManager
        .assetAssign(AssignDetail)
        .then((response) => {
          setLoading(false);
          setmsgType("success");
          setShowMSG(response?.data?.message || "assigned successfully");
          navigate("/hrtools/assets-management/asset-assignment");
          setOpen && setOpen(false);
        })
        .catch((errors) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(errors?.response?.data?.message || "failed to assign");
        });
    },
  });

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="row  d-flex justify-content-center">
          <div className="col-md-8">
            <Input
              label="Email"
              name="email_id"
              type="email"
              placeholder="Please Enter Assigned Employee Email"
              value={values.email_id}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.email_id && !errors.email_id}
              touched={touched.email_id}
              error={errors.email_id}
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-10 d-flex justify-content-end">
            <Button label="Submit" type="submit" />
          </div>
        </div>
      </form>
      <CoreLoader show={loading} disableLines />
    </div>
  );
};

export default AssetAssign;
