import CircularBar from "common/components/CircularBar";
import React from "react";

const CardComponents = ({ contentData = {}, titles, data }) => {
  return (
    <div className="container-fluid">
      <div className="border rounded-3 p-3 main_table">
        <div className="row align-items-center">
          <div className="col-lg-6">{contentData.subTitle || "No subtitle provided"}</div>
          <div className="row p-2 " style={{ textAlign: "-webkit-center" }}>
            <div className="col-lg-4  col-md-6 col-sm-4">
              <CircularBar percentage={(data && data[titles.title1]) || 0} color="#4dc8f4" width={"100%"} />
              {(titles && titles.title1) || " "}
            </div>
            <div className="col-lg-4  col-md-6 col-sm-4">
              <CircularBar percentage={(data && data[titles.title2]) || 0} color="#a7cf55" width={"100%"} />
              {(data && titles.title2) || " "}
            </div>
            <div className="col-lg-4  col-md-6 col-sm-4">
              <CircularBar percentage={(data && data[titles.title3]) || 0} color="#f3755b" width={"100%"} />
              {(data && titles.title3) || " "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponents;
