import React, { useState } from "react";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";

const usePasswordToggle = () => {
  const [visible, setVisible] = useState(false);

  const icon = visible ? (
    <IoEyeOffOutline onClick={() => setVisible(!visible)} />
  ) : (
    <IoEyeOutline onClick={() => setVisible(!visible)} />
  );
  const inputType = visible ? "text" : "password";
  return [inputType, icon];
};

export default usePasswordToggle;
