import React, { useState, useEffect } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [activeTitle, setActiveTitle] = useState("");

  let paths = {};
  tabs.forEach((tab, index) => {
    paths[tab.title.replace(" ", "-").toLowerCase()] = index;
  });

  let helperFunc = () => {
    let hashPath = window.location.hash.replace("#", "");

    if (paths[hashPath]) {
      return paths[hashPath];
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setActiveTab(helperFunc());
  }, [window.location.hash]);

  useEffect(() => {
    setActiveTitle(tabs[activeTab]?.title);
  }, [activeTab, tabs]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    const tabTitle = tabs[index].title.replace(" ", "-").toLowerCase();
    window.location.hash = `#${tabTitle}`;
  };

  return (
    <>
      <div className="mt-3">
        <ul className="nav nav-tabs custom  navresponsive">
          {tabs &&
            tabs.map((tab, index) => (
              <li
                key={index}
                className={`nav-item ${activeTab === index ? "active" : ""} navitemresponsive`}
                onClick={() => handleTabClick(index)}
              >
                <a
                  className={`nav-link ${activeTab === index ? "active" : ""}`}
                  aria-current="page"
                  href={`#${tab.title.replace(" ", "-").toLowerCase()}`}
                >
                  {tab.title}
                </a>
              </li>
            ))}
        </ul>
        <hr />

        <div className="tab-content">{tabs[activeTab]?.content}</div>
      </div>
    </>
  );
};

export default Tabs;
