import React from "react";
import "../../../assets/style/UnauthorizedPage.css"; // Import the CSS file for styling

const UnauthorizedPage = () => {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>Unauthorized Access</h1>
        <p>Oops! It seems like you don't have permission to access this page.</p>
        <p>Please contact the administrator for assistance.</p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
