import React from "react";
import {
  AddressDetails,
  Candidatedetails,
  EducationDetail,
  Documents,
  CandidatePreview,
  ExperienceDetails,
} from "./forms";
import { useParams } from "react-router-dom";
// import EducationDetail from "./EducationDetail";
const UserDetail = () => {
  const params = useParams();
  const { activePage } = params;
  let renderForms = {
    candidatedetails: Candidatedetails,
    addressdetails: AddressDetails,
    educationdetails: EducationDetail,
    documentDetails: Documents,
    candidatePreview: CandidatePreview,
    experiencedetails: ExperienceDetails,
  };

  const renderForm = () => {
    let Component = renderForms[activePage];
    if (!Component) return;
    return (
      <div className="mx-4 py-4 boxshadow borderradius">
        <Component />
      </div>
    );
  };

  return (
    <div className="py-3 pb-5 w-100">
      <div className="d-flex gap-3 my-3  align-items-center subheaderbackground p-2 px-4">
        <h2>Candidate Onboarding</h2>
      </div>
      {renderForm()}
    </div>
  );
};

export default UserDetail;
