import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { EducationFormSchema } from "../../../../utils/formValidationUtils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AlertMessage, Button, CoreLoader, DownloadIcon, GroupInput, Input, Label } from "common/components";
import { AddEducationModal } from ".";
import apiManager from "networking/baseAPIManager";
import "../../../../assets/style/Responsive.css";
const EducationDetail = () => {
  let params = useParams();
  let empid = params?.empid;

  const [modalShow, setModalShow] = useState(false);
  const [extraEducationData, setExtraEducationData] = useState([]);
  // const [extraEducationData, setExtraEducationData] = useState(documents?.extra);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [documents, setDocuments] = useState({});
  const [showNext, setShowNext] = useState(false);

  const getcandidateDocuments = () => {
    setLoading(true);
    apiManager
      .candidateDocuments(empid)
      .then((res) => {
        setLoading(false);
        setDocuments(structuredClone(res?.data?.data));
        setExtraEducationData([...res?.data?.data?.extra]);
        setShowNext(res?.data?.nextPage);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    empid && getcandidateDocuments();
  }, [empid]);

  const forminitialValue = {
    secondaryPer: documents?.secondary_passing || "",
    secondaryResult: documents?.secondary_doc || null,
    seniorPer: documents?.senior_passing || "",
    seniorResult: documents?.senior_doc || null,
    bachelorsPer: documents?.bachelor_passing || "",
    bachelorsResult: documents?.bachelor_doc || null,
    // bachelorsDegree: null,
    // mastersPer: "",
    // mastersResult: null,
    // mastersDegree: null,
  };

  const navigate = useNavigate();
  const { handleChange, handleBlur, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
    initialValues: forminitialValue,
    validationSchema: EducationFormSchema,
    onSubmit: (values, action) => {
      console.log(values, "values");
      handleSave(values);
    },
    enableReinitialize: true,
  });

  const handleSave = (data) => {
    const formData = new FormData();
    formData.append("empid", empid);

    extraEducationData &&
      extraEducationData.map((item, index) => {
        formData.append(item.degreeField, item.degreefile);
      });

    formData.append("secondary_passing", data.secondaryPer);
    formData.append("secondary_doc", data.secondaryResult);
    formData.append("senior_passing", data.seniorPer);
    formData.append("senior_doc", data.seniorResult);
    formData.append("bachelor_passing", data.bachelorsPer);
    formData.append("bachelor_doc", data.bachelorsResult);
    // formData.append("undergraduate_passing", data.secondaryPer);
    // formData.append("undergraduate_doc", data.secondaryPer);
    if (extraEducationData) {
      formData.append("extraData", JSON.stringify(extraEducationData));
    }

    setLoading(true);
    apiManager
      .addEmployeeDocuments(formData)
      .then((response) => {
        setLoading(false);
        setShowMSG("Details updated");
        setmsgType("success");
        navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`);
      })
      .catch((error) => {
        setLoading(false);
        setShowMSG("failed to update Details");
        setmsgType("error");
        console.log(error);
      });
  };

  const decimalFormat = (value) => {
    const parsedValue = parseFloat(value);

    if (parsedValue > 100) {
      const valueString = parsedValue.toString();
      // Add a decimal point after the first two digits
      return valueString.slice(0, 2) + "." + valueString.slice(2, 4);
    }

    return value;
  };
  const renderFileInput = (name) => {
    if (values[name] && typeof values[name] == "string") {
      const downloadAttachment = async () => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        const attachmentData = `${BASE_URL}/${values[name]}`;

        if (!attachmentData) {
          console.error("No attachment data found.");
          return;
        }

        try {
          const response = await fetch(attachmentData);
          const blob = await response.blob();
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", values[name]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } catch (error) {
          console.error("Error fetching or triggering download:", error);
        }
      };

      return (
        <div className=" w-50 d-flex gap-3 align-items-center ">
          <Button onClick={() => downloadAttachment()} label="Download" type="button" />
          {documents.status == "pending" ? (
            <span
              className="material-symbols-outlined pointer"
              onClick={() => {
                setFieldValue([name], undefined);
              }}
            >
              edit
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      <>
        <div className="row form-outline  row align-items-center">
          <Label label="Upload Result" parentclasses="col-xl-4 col-lg-4 col-md-6" required />
          <Input
            parentclasses="col-xl-8 col-lg-8 col-md-5 col-sm-12"
            type="file"
            label="Upload Result"
            name={name}
            required
            onChange={(e) => {
              setFieldValue(name, e.target.files[0]);
            }}
            onBlur={handleBlur}
            isValid={touched[name] && !errors[name]}
            touched={touched[name]}
            error={errors[name]}
          />
        </div>
      </>
    );
  };

  const renderFileInputloop = (index) => {
    let item = extraEducationData[index];
    let image = item.degreefile;
    const downloadAttachment = async () => {
      let BASE_URL = process.env.REACT_APP_BASE_URL;
      const attachmentData = `${BASE_URL}/${image}`;

      if (!attachmentData) {
        console.error("No attachment data found.");
        return;
      }

      try {
        const response = await fetch(attachmentData);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", image);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        console.error("Error fetching or triggering download:", error);
      }
    };
    if (image) {
      return (
        <div className="w-50  d-flex gap-3 align-items-center">
          {/* <DownloadIcon link={extraEducationData[index].degreefile} /> */}
          <Button onClick={() => downloadAttachment()} label="Download" type="button" />
          {documents.status == "pending" ? (
            <span
              className="material-symbols-outlined pointer"
              onClick={() => {
                extraEducationData[index].degreefile = undefined;
                setExtraEducationData([...extraEducationData]);
              }}
            >
              edit
            </span>
          ) : (
            ""
          )}
        </div>
      );
    }

    return (
      <>
        <Input
          type="file"
          name={extraEducationData[index]}
          required
          onChange={(e) => {
            extraEducationData[index].degreefile = e.target.files[0];
            setExtraEducationData([...extraEducationData]);
          }}
          onBlur={handleBlur}
          isValid={touched[extraEducationData[index]] && !errors[extraEducationData[index]]}
          touched={touched[extraEducationData[index]]}
          error={errors[extraEducationData[index]]}
        />
      </>
    );
  };

  const rendermultiFields = () => {
    if (!extraEducationData || !Array.isArray(extraEducationData)) {
      return;
    }
    return extraEducationData.map((item, index) => {
      return (
        <div key={index} className="container py-5 ">
          <div className="form-outline row align-items-center ms-0 ">
            <Label label="Degree" parentclasses="col-xl-2 col-lg-3 col-md-3 col-sm-6  " />
            <GroupInput
              parentclasses="col-xl-4 col-lg-3 col-md-3"
              label={item.degreeField}
              placeholder="60"
              value={item.degreepercent || ""}
              group="%"
              disabled
              onChange={(e) => handleChange(e, index, "degreepercent")}
              onBlur={() => handleBlur(index, "degreepercent")}
              isValid={touched[index]?.degreepercent && !errors[index]?.degreepercent}
              touched={touched[index]?.degreepercent}
              error={errors[index]?.degreepercent}
            />
            <div className="col-xl-2 col-lg-3 col-md-2.">{renderFileInputloop(index)}</div>
            <div className="col-lg-2 col-xl-4 col-md-1">
              <span
                className="material-symbols-outlined pointer"
                onClick={() => {
                  extraEducationData.splice(index, 1);
                  setExtraEducationData([...extraEducationData]);
                }}
              >
                close
              </span>
            </div>
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

      <div className="gap-3 text-center subheaderbackgroundoffWhite p-2 d-flex align-items-center justify-content-center">
        <h2>Candidate Education Details</h2>
      </div>
      <form onSubmit={handleSubmit}>
        <div className=" container py-5  gap-4 ">
          <div className="form-outline row align-items-center ms-0 ">
            <Label label="Secondary Passing" parentclasses="col-xl-2 col-lg-3 col-md-3 col-sm-6  " required />
            <GroupInput
              parentclasses="col-xl-4 col-lg-3  col-md-3 downbtn"
              maxLength={5}
              type="text"
              name="secondaryPer"
              placeholder="Secondary Passing"
              value={decimalFormat(values.secondaryPer || "")}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.secondaryPer && !errors.secondaryPer}
              touched={touched.secondaryPer}
              error={errors.secondaryPer}
              group="%"
            />
            <div className="col-xl-6 col-lg-6 col-md-6"> {renderFileInput("secondaryResult")}</div>
          </div>

          <div className="  form-outline row align-items-center ms-0 pt-3">
            <Label label="Senior Passing" parentclasses="col-xl-2 col-lg-3  col-md-3  col-sm-6" required />
            <GroupInput
              parentclasses="col-xl-4 col-lg-3  col-md-3 downbtn"
              maxLength={5}
              type="text"
              name="seniorPer"
              placeholder="Senior Passing"
              value={values.seniorPer || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.seniorPer && !errors.seniorPer}
              touched={touched.seniorPer}
              error={errors.seniorPer}
              group="%"
            />
            <div className="col-xl-6 col-lg-6 col-md-6 "> {renderFileInput("seniorResult")}</div>
          </div>

          <div className=" form-outline  row align-items-center ms-0 pt-3">
            <Label label="Bachelor's Passing" parentclasses="col-xl-2 col-lg-3 col-md-3 col-sm-6" required />
            <GroupInput
              type="text"
              maxLength={5}
              parentclasses="col-xl-4 col-lg-3 col-md-3 downbtn"
              name="bachelorsPer"
              placeholder="Bachelor's Passing"
              value={values.bachelorsPer || ""}
              onChange={handleChange}
              onBlur={handleBlur}
              isValid={touched.bachelorsPer && !errors.bachelorsPer}
              touched={touched.bachelorsPer}
              error={errors.bachelorsPer}
              group="%"
            />
            <div className="col-xl-6 col-lg-6 col-md-6"> {renderFileInput("bachelorsResult")} </div>
          </div>
        </div>
        <div className="mt-1">{rendermultiFields()}</div>
        <div className="d-flex gap-3 justify-content-end px-4">
          <Button
            type="button"
            label="Add Education"
            onClick={() => {
              setModalShow(!modalShow);
            }}
          />
        </div>

        {modalShow && (
          <AddEducationModal
            show={modalShow}
            setShow={setModalShow}
            data={extraEducationData}
            setData={setExtraEducationData}
          />
        )}

        <div className=" button-container d-flex gap-3 justify-content-end subheaderbackground p-2 px-4 mt-3">
          <Button
            classes=" responsiver-button "
            type="button"
            label="Back"
            onClick={() => navigate(`/hrtools/employee-onboarding/addressdetails/${empid}`)}
          />

          <Button
            type="submit"
            label="Save & Continue"
            classes=" responsiver-button btn-outline-dark bg-light text-primary"
          />
          {showNext ? (
            <Button
              classes=" responsiver-button "
              type="button"
              label="Next"
              onClick={() => navigate(`/hrtools/employee-onboarding/experiencedetails/${empid}`)}
            />
          ) : (
            ""
          )}
        </div>
      </form>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default EducationDetail;
