import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const Privaterout = () => {
  const { token } = useSelector((state) => state.data);

  let logedIn = !!token;
  return <>{logedIn ? <Outlet /> : <Navigate to={"/unauthorized"} />}</>;
};

export default Privaterout;
