export const setGetLocalData = (key, data) => {
  if (data) {
    sessionStorage.setItem(key, data);
  } else {
    return sessionStorage.getItem(key);
  }
};

export const clearLocalData = (key) => {
  if (key) {
    sessionStorage.removeItem(key);
  } else {
    sessionStorage.clear();
  }
};
