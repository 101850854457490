import React from "react";
import Style from "./css/CardWrapper.module.css";

const CardWrapper = (props) => {
  const { children, className, onClick } = props;
  return (
    <div className={`${Style.card} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export default CardWrapper;
