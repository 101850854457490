import React from "react";
import { Modal } from "rsuite";
import "./css/CommonModal.css"; // Import your custom CSS

const CommonModal = (props) => {
  const { open, size, onClose, title, className } = props;

  return (
    <div>
      <Modal size={size || "sm"} open={open} onClose={onClose} className={className}>
        <Modal.Header className="custom-header">
          <Modal.Title className="custom-title">{title}</Modal.Title>
          {/* The close button is automatically included in Modal.Header */}
        </Modal.Header>
        <Modal.Body>{props.children}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CommonModal;
