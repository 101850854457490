import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/style/UnauthorizedPage.css";

function ComingSoonPage() {
  return (
    <div className="unauthorized-container">
      <div className="unauthorized-content">
        <h1>Coming Soon</h1>
        <p>Our website is under construction.</p>
        <p>We'll be here soon with our new awesome site. Stay tuned!</p>
      </div>
    </div>
  );
}

export default ComingSoonPage;
