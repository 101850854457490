import AdvanceSalaryPending from "Components/ManageFund/AdvanceSalary/AdvanceSalaryPending";
import { SubHeader, Tabs } from "UI_Components";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AdvanceSalaryRequest from "Components/ManageFund/AdvanceSalary/AdvanceSalaryRequest";
import AdvanceSalaryComplete from "Components/ManageFund/AdvanceSalary/AdvanceSalaryComplete";
import AdvanceSalaryApproval from "Components/ManageFund/AdvanceSalary/AdvanceSalaryApproval";
import apiManager from "networking/baseAPIManager";

export default function AdvanceSalaryManageFund() {
  const [loading, setLoading] = useState(false);
  const [advanceSalaryData, setAdvanceSalaryData] = useState([]);

  const advanceSalaryList = () => {
    setLoading(true);
    apiManager
      .advanceSalaryList()
      .then((res) => {
        console.log(res?.data?.List);
        setAdvanceSalaryData(res?.data?.List);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    advanceSalaryList();
  }, []);

  let completedData = advanceSalaryData.filter((data) => {
    const lowercaseStatus = String(data?.status).toLowerCase();
    return ["approved", "rejected", "cancelled"].includes(lowercaseStatus);
  });
  let pendingData = advanceSalaryData.filter((data) => String(data?.status).toLowerCase() === "pending");
  let approvalData = pendingData;
  let arrData = [
    { title: "Create Request", content: <AdvanceSalaryRequest fetchapi={advanceSalaryList} /> },
    {
      title: "Pending",

      content: <AdvanceSalaryPending fetchapi={advanceSalaryList} advanceSalaryPendingData={pendingData} />,
    },
    { title: "Complete", content: <AdvanceSalaryComplete fetchapi={advanceSalaryList} completeData={completedData} /> },
    {
      title: "Approval Request",
      content: <AdvanceSalaryApproval fetchapi={advanceSalaryList} approvalData={approvalData} />,
    },
  ];

  console.log(advanceSalaryData, "advanceSalaryData");
  const [tabs, setTabs] = useState(arrData);

  useEffect(() => {
    setTabs([...arrData]);
  }, [advanceSalaryData]);
  return (
    <div>
      <SubHeader location={useLocation()} />
      <div className="row m-0 p-0">
        <div className="col-lg-12 ">
          <Tabs tabs={tabs} />
        </div>
      </div>
    </div>
  );
}
