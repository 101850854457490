import { Field, FieldArray, Formik } from "formik";
import React from "react";
import { documentFormSchema } from "../../../../utils/formValidationUtils";

function Document() {
  const formInitialValues = {
    photo: "",
    aadharCard: "",
    panCard: "",
    paySlip: "",
    resignationLetter: "",
    currentCompensation: "",
    offerCompensation: "",
    addExperience: [],
    terms: false,
  };

  return (
    <div>
      <h1>Candidate Documents</h1>
      <Formik
        initialValues={formInitialValues}
        validationSchema={documentFormSchema}
        onSubmit={(values) => console.log(values, "DocumentDetails")}
      >
        {({ values, handleChange, handleBlur, handleSubmit, touched, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <div className="form_con">
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Upload Photo</label>
                    <input
                      type="file"
                      className="form-control"
                      name="photo"
                      onChange={(e) => {
                        setFieldValue("photo", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.photo && !errors.photo}
                    />
                    {touched.photo && errors.photo && <span className="error_message">{errors.photo}</span>}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Upload Aadhar Card</label>
                    <input
                      type="file"
                      className="form-control"
                      name="aadharCard"
                      onChange={(e) => {
                        setFieldValue("aadharCard", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.aadharCard && !errors.aadharCard}
                    />
                    {touched.aadharCard && errors.aadharCard && (
                      <span className="error_message">{errors.aadharCard}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Upload PAN Card</label>
                    <input
                      type="file"
                      className="form-control"
                      name="panCard"
                      onChange={(e) => {
                        setFieldValue("panCard", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.panCard && !errors.panCard}
                    />
                    {touched.panCard && errors.panCard && <span className="error_message">{errors.panCard}</span>}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Upload PaySlip</label>
                    <input
                      type="file"
                      className="form-control"
                      name="paySlip"
                      onChange={(e) => {
                        setFieldValue("paySlip", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.paySlip && !errors.paySlip}
                    />
                    {touched.paySlip && errors.paySlip && <span className="error_message">{errors.paySlip}</span>}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Upload ResignationLetter</label>
                    <input
                      type="file"
                      className="form-control"
                      name="resignationLetter"
                      onChange={(e) => {
                        setFieldValue("resignationLetter", e.target.files[0]);
                      }}
                      onBlur={handleBlur}
                      isValid={touched.resignationLetter && !errors.resignationLetter}
                    />
                    {touched.resignationLetter && errors.resignationLetter && (
                      <span className="error_message">{errors.resignationLetter}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Current Compensation</label>
                    <input
                      type="text"
                      className="form-control"
                      name="currentCompensation"
                      value={values.currentCompensation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.currentCompensation && !errors.currentCompensation}
                    />
                    {touched.currentCompensation && errors.currentCompensation && (
                      <span className="error_message">{errors.currentCompensation}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row justify-content-md-center">
                <div className="col-sm-5">
                  <div className="mb-3">
                    <label className="form-label">Offer Compensation</label>
                    <input
                      type="text"
                      className="form-control"
                      name="offerCompensation"
                      value={values.offerCompensation}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.offerCompensation && !errors.offerCompensation}
                    />
                    {touched.offerCompensation && errors.offerCompensation && (
                      <span className="error_message">{errors.offerCompensation}</span>
                    )}
                  </div>
                </div>
              </div>
              <FieldArray
                name="addExperience"
                render={(arrayHelpers) => (
                  <div>
                    {values.addExperience.map((experience, index) => (
                      <div key={index} className="experience_form">
                        <div>
                          <h5>{`Add Experience ${index + 1}`}</h5>
                        </div>
                        <div className="row justify-content-md-center">
                          <div className="col-sm-8">
                            <div className="mb-3">
                              <Field
                                className="form-control"
                                type="text"
                                name={`addExperience.${index}.companyName`}
                                id={`addExperience.${index}.companyName`}
                                placeholder="Company Name"
                                aria-label="Company Name"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-md-center">
                          <div className="col-sm-8">
                            <div className="mb-3">
                              <label className="form-label">Upload Offer Letter</label>

                              <input
                                className="form-control"
                                type="file"
                                name={`addExperience.${index}.offerLetter`}
                                id={`addExperience.${index}.offerLetter`}
                                onChange={(e) => {
                                  setFieldValue("addExperience.${index}.offerLetter", e.target.files[0]);
                                }}
                                onBlur={handleBlur}
                                isValid={touched.paySlip && !errors.paySlip}
                              />
                              {touched.paySlip && errors.paySlip && (
                                <span className="error_message">{errors.offerLetter}</span>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-md-center">
                          <div className="col-sm-8">
                            <div className="mb-3">
                              <label className="form-label">Upload Experience Letter</label>
                              <input
                                className="form-control"
                                type="file"
                                name={`addExperience.${index}.experienceLetter`}
                                onChange={(e) => {
                                  setFieldValue(`addExperience.${index}.experienceLetter`, e.target.files[0]);
                                }}
                                id={`addExperience.${index}.experienceLetter`}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-md-center">
                          <div className="col-sm-8">
                            <div className="d-grid gap-2">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove Experience
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="row justify-content-md-center">
                      <div className="col-sm-8">
                        <div className="start_onboarding">
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => arrayHelpers.insert(values.addExperience.length + 1, {})}
                          >
                            Add Experience
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              />
              <div className="col-12">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleChange}
                    isInvalid={!!errors.terms}
                    name="terms"
                    required
                  />
                  <label className="form-check-label" for="invalidCheck2">
                    Agree to terms and conditions
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col mt-4 text-align">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export default Document;
