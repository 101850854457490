import React from "react";
import { useNavigate } from "react-router-dom";

function SCard({ tools, titleClass, contentClass }) {
  const navigate = useNavigate();
  const colors = ["#4dc8f4", "#a7cf55", "#f3755b", "#efb346"];
  const handleCardClick = (NavigationPath) => {
    navigate(NavigationPath);
  };

  return (
    <div className="container-fluid">
      <div className="row  g-3  pt-2">
        <div className="col-md-12  col-lg-12">
          <div className="row">
            {tools.map((tool, index) => (
              <div
                key={index}
                className="col-lg-6 col-xxl-3  col-xl-3 col-md-6 "
                onClick={() => handleCardClick(tool.path)}
              >
                <div
                  className="  border  rounded-3 mb-3 p-3"
                  style={{ backgroundColor: colors[index % colors.length] }}
                >
                  <div className={`bg-custom`}>
                    <div className="row p-3 ">
                      <div className="col-lg-8 col-md-8 ">
                        <div className="row  ">
                          <div className="col-lg-12  ">
                            <div className={titleClass}>{tool.cardTitle} </div>
                          </div>
                          <div className="col-lg-12 mt-2">
                            <div className={contentClass}>{tool.cardContent}</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4 ">
                        <img src={tool.image} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SCard;
