import axios from "axios";
import { setGetLocalData } from "utils/localStorageHelper";

let BASE_URL = process.env.REACT_APP_BASE_URL;

const authApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
});

authApi.interceptors.request.use(
  function (config) {
    const token = setGetLocalData("token");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.status == 401 || error?.response?.status == 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default authApi;
