import React, { useId } from "react";
import styles from "./css/FloatingInput.module.css";

const FloatingInput = (props) => {
  let { label, parentclasses, labelclasses, touched, error, required, icons } = props;

  let uniqueId = useId();

  return (
    <div>
      <div className={`${styles.inputBox} w-100 mb-4  ${parentclasses}`}>
        <label className={` ${styles.label} ${labelclasses}`} htmlFor={"floatinginput" + uniqueId}>
          {label} {required && <span className="astricRequired">*</span>}
        </label>
        <div className={`${styles.inputIcons} d-flex justify-content-end`}>{icons}</div>
        <input id={"floatinginput" + uniqueId} className={`form-control ${styles.input}`} {...props} />
      </div>

      {touched && error && <div className={`${styles.error} d-flex justify-content-start`}>{error}</div>}
    </div>
  );
};

export default FloatingInput;
