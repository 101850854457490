import React, { useId } from "react";
import styles from "./css/input.module.css";
import Label from "./Label";

function Input(props) {
  let { parentclasses, inputclasses, touched, error, condition, type } = props;

  let uniqueId = useId();

  let inputProps = { ...props };
  delete inputProps.required;

  return (
    <div className={parentclasses}>
      <input
        type={type}
        id={"input" + uniqueId}
        className={`form-control ${styles.input} ${inputclasses}`}
        {...inputProps}
        condition={condition}
      />
      {touched && error && <span className={styles.error}>{error}</span>}
    </div>
  );
}

export default Input;
