import { Table } from "UI_Components";
import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";

export default function HrtoolDashoardTable() {
  const [loading, setLoading] = useState(false);
  const [empListData, setEmpListData] = useState([]);
  const navigate = useNavigate();

  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    if (empListData && empListData?.length > 0 && itemsPerPage > 0) {
      const start = (activePage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const SliceData = empListData?.slice(start, end);

      const paginatedData = SliceData?.map((item, index) => ({
        ...item,
        sno: start + index + 1,
      }));

      setDisplayedData(paginatedData);
    }
  }, [activePage, empListData, itemsPerPage]);

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");

  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "firstname" },

    { header: "Mobile.No", field: "mobile_number" },
    { header: "Designation", field: "designation", customHtml: (data) => data.designation || "N/A" },
    { header: "Onboarding Date", field: "createdAt", customHtml: (data) => dateHtml(data.createdAt) },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        let statusColor;
        let statusText;

        switch (data.status?.toLowerCase()) {
          case "completed":
            statusColor = "blue";
            statusText = data.status;
            break;
          case "pending":
            statusColor = "red";
            statusText = data.status;
            break;
          case "approved":
            statusColor = "green";
            statusText = "In Process";
            break;
          default:
            statusColor = "black";
            statusText = "N/A";
            break;
        }

        return <span style={{ color: statusColor }}>{statusText}</span>;
      },
    },
    { header: "", field: "actions", btnType: "primary", customHtml },
  ];

  const handleItemClick = (data) => {
    navigate(`/hrtools/employee-onboarding/candidatePreview/${data._id}`);
  };

  useEffect(() => {
    employeeList();
  }, []);
  const employeeList = () => {
    setLoading(true);
    apiManager

      .employeeList()
      .then((response) => {
        setLoading(false);
        setEmpListData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row ">
          <div className="col-lg-12 px-2 pt-3">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" className="pointer" />
          </div>
          <div className="pt-5 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={empListData?.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
        <CoreLoader show={loading} disableLines />
      </div>
    </div>
  );
}
