import React, { useEffect, useState } from "react";
import SCard from "../../UI_Components/SCard";
import SubHeader from "../../UI_Components/SubHeader";
import { useLocation } from "react-router-dom";
import totalEmpolyees2 from "../../../src/assets/images/totalEmployees2.png";
import onBoarding from "../../../src/assets/images/onboarding.png";
import offBoarding from "../../../src/assets/images/offboarding.png";
import Charts from "common/components/Chart";
import HrtoolDashoardTable from "Components/GraphData/HrToolDashboard/HrtoolDashoardTable";
import apiManager from "networking/baseAPIManager";
import CardComponents from "Components/GraphData/HrToolDashboard/Cardcomponents";
import "../../assets/style/Responsive.css";

const Hrtools = () => {
  const [loading, setLoading] = useState(false);
  const [yearlGraphData, setYearlGraphData] = useState();
  const [deparmentData, setDepartmentData] = useState([]);
  const [InformationData, setInformationData] = useState();
  const [circularBarData, setCircularBarData] = useState();
  const [employeeBarData, setEmployeeBarData] = useState(0);
  const [assetsBarData, setAssetsBarDat] = useState();

  const tools = [
    {
      cardTitle: "Total Employees",
      cardContent: `${InformationData?.totalEmployeeCount || 0}`,
      image: totalEmpolyees2,
    },
    {
      cardTitle: "Onboarding",
      cardContent: `${InformationData?.totalnewOnboarding || 0}`,
      image: onBoarding,
    },
    {
      cardTitle: "Off-Boarding",
      image: offBoarding,
      cardContent: `${InformationData?.totaloffOnboarding || 0}`,
    },
  ];
  const cardData = [
    {
      id: 1,
      // imgIcon: candidate,
      title: "(505)",
      subTitle: "Employee On Boarding",
      path: "",
    },
    {
      id: 2,
      // imgIcon: recruitment,
      title: "(365)",
      subTitle: "Employee On Boarding",
      path: "",
    },
    {
      id: 3,
      // imgIcon: approved,
      title: "(26.k)",
      subTitle: "Net Salary",
      path: "",
    },
  ];
  const donutoption = {
    series: deparmentData?.series,

    chart: {
      type: "donut",
      width: "10%",
    },

    title: {
      // text: "Employee On Details",
      align: "left",
      style: {
        fontWeight: "600",
        fontSize: "20px",
      },
    },
    labels: deparmentData?.departments,

    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            // total: {
            //   showAlways: true,
            //   show: true,
            // },
          },
        },
      },
    },
    legend: {
      fontSize: "12px",
      fontFamily: "Arial, sans-serif",
      fontWeight: "600",
      position: "left",
      offsetY: 5,
    },

    tooltip: {
      style: {
        fontSize: "14px",
      },
    },
    responsive: [
      {
        breakpoint: 80,
        options: {
          chart: {},
          legend: {
            position: "bottom",
          },
        },
        breakpoint: 1024,
      },
    ],
    colors: [
      "#267fc3",
      "#00e396",
      "#feb019",
      "#ff4560",
      "#775dd0",
      "#FF5733",
      "#3ea34f",
      "#0681dd",
      "#ff6666",
      "#ffb3d9",
      "#b3b3ff",
    ],
  };

  const areaChartData = {
    series: yearlGraphData?.series,

    dataLabels: {
      enabled: false,
    },
    chart: {
      type: "area",
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true,
        },
      },
    },
    markers: {
      colors: ["#6289ec", "#ff5733"],
      size: 1,
    },
    title: {
      text: "Employee Status",
      align: "left",
      style: {
        fontWeight: "600",
        fontSize: "20px",
        margintop: "10px",
      },
    },

    stroke: {
      colors: ["#6289ec", "#ff5733"],
      curve: "smooth",
    },

    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
      marker: {
        show: true,
        fillColors: ["#6289ec", "#ff5733"], // Update the color here
      },
      style: {
        // background: ["#6289ec", "#ff5733"],
      },
    },

    legend: {
      markers: {
        width: 12,
        height: 12,
        radius: 6,
        fillColors: ["#6289ec", "#ff5733"],
        strokeWidth: 0,
      },
    },
  };
  const calculatePercentages = (data) => {
    const total = Object.values(data).reduce((sum, count) => sum + count, 0);
    if (total === 0) {
      return Object.keys(data).reduce((percentages, key) => {
        percentages[key] = "0";
        return percentages;
      }, {});
    }
    return Object.keys(data).reduce((percentages, key) => {
      percentages[key] = ((data[key] / total) * 100).toFixed(0);
      return percentages;
    }, {});
  };

  useEffect(() => {
    hrInformationData();
    departmentDatafetch();
    hrdDashboardYearlyData();
    hrdDashboardCircularData();
    hrEmployeeData();
    hrAssetsData();
  }, []);

  const hrInformationData = () => {
    setLoading(true);
    apiManager
      .HrDashboardEmData()
      .then((response) => {
        setLoading(false);
        setInformationData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const departmentDatafetch = () => {
    setLoading(true);
    apiManager
      .HrDepartmentCount()
      .then((response) => {
        setLoading(false);
        setDepartmentData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hrdDashboardYearlyData = () => {
    setLoading(true);
    apiManager
      .HrDashboardYearly()
      .then((response) => {
        setLoading(false);
        setYearlGraphData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const hrdDashboardCircularData = () => {
    setCircularBarData({ Completed: 0, InProcess: 0, Pending: 0 });
    setLoading(true);
    apiManager
      .HrOnboardingStatus()
      .then((response) => {
        setLoading(false);
        setCircularBarData(calculatePercentages(response.data));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hrEmployeeData = () => {
    setLoading(true);
    apiManager
      .HrEmployeeStatus()
      .then((response) => {
        setLoading(false);
        setEmployeeBarData(calculatePercentages(response.data));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hrAssetsData = () => {
    setLoading(true);
    apiManager
      .HrDashboardAsset()
      .then((response) => {
        setLoading(false);
        setAssetsBarDat(calculatePercentages(response.data));
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <div>
      <div>
        <SubHeader location={useLocation()} />
      </div>
      <div className="container-fluid">
        <div className="">
          <SCard tools={tools} titleClass="text-light" contentClass="text-light" />

          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-xl-4  col-md-6 cardpadding">
                <CardComponents
                  contentData={{ subTitle: "Employee Onboarding" }}
                  data={circularBarData}
                  titles={{ title1: "Completed", title2: "InProcess", title3: "Pending" }}
                />
              </div>
              <div className="col-lg-6 col-xl-4  col-md-6 cardpadding">
                <CardComponents
                  contentData={{ subTitle: "Employee Status" }}
                  data={employeeBarData}
                  titles={{
                    title1: "OnLeave",

                    title2: "Present",

                    title3: "Absent",
                  }}
                />
              </div>
              <div className="col-lg-6  col-xl-4 col-md-6 cardpadding">
                <CardComponents
                  contentData={{ subTitle: "Asset Status" }}
                  data={assetsBarData}
                  titles={{ title1: "Assign", title2: "Inventory", title3: "Repair" }}
                />
              </div>
            </div>
          </div>

          <div className="row p-3 g-3 h-25">
            <div className=" col-lg-12  col-xl-8">
              <div className=" p-3 main_table">
                {yearlGraphData?.series?.length > 0 && <Charts option={areaChartData} type="area" height={350} />}
              </div>
            </div>

            <div class=" col-lg-12 col-xl-4 h-full  p-2 border rounded-4 chartboxshadow">
              <h5 class="fw-bold mt-4">Employee Deparment</h5>
              <div class="flex justify-center item-center">
                {deparmentData?.series?.length > 0 && <Charts option={donutoption} type="donut" height={240} />}
              </div>
            </div>
          </div>
          <HrtoolDashoardTable />
        </div>
      </div>
    </div>
  );
};

export default Hrtools;
