import { SubHeader, Tabs } from "UI_Components";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import { AlertMessage, Button, CoreLoader } from "common/components";

export default function GenerateLetter({ onClose, selectedItem }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const location = useLocation();
  const state = location?.state;
  const generateLetterData = state?.data?.document;
  const [getBlob, setGetBlob] = useState("");
  const getGenerateDocument = () => {
    setLoading(true);
    const payload = {
      id: state ? generateLetterData?.empId : selectedItem?.empid?._id,
      document: state ? generateLetterData?.document : selectedItem?.document,
    };
    apiManager
      .generateDocumnet(payload)
      .then((response) => {
        setGetBlob(response?.data);
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
      })
      .catch((errors) => {
        console.log(errors);
        setmsgType("error");
        setShowMSG(errors?.response?.data?.message || "failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const approvedDocument = () => {
    const payload = { id: state ? generateLetterData?._id : selectedItem?._id };

    apiManager
      .ApprovedDocument(payload)
      .then((response) => {
        setmsgType("success");
        setShowMSG(response?.data?.message || "details updated successfully");
        navigate("/hrtools/generate-documents");
        {
          onClose && onClose();
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setmsgType("error");
        setShowMSG(error?.response?.data?.message || "failed to update");
      });
  };
  useEffect(() => {
    getGenerateDocument();
  }, [state || selectedItem]);

  return (
    <>
      <div className="generate-letter-container">
        <div className="alert-container">
          <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
        </div>
        <SubHeader location={useLocation()} />
        <div className="row m-0 p-5">
          <div className="col-lg-12 ">
            <div className="container pb-5">
              <div className="form-container">
                <div className="row d-flex align-items-center justify-content-center">
                  <div dangerouslySetInnerHTML={{ __html: getBlob }}></div>
                  {/* {getBlob && <iframe src={getBlob} title="Document Preview" width="50%" height="650px" />} */}
                </div>
              </div>
            </div>
            <div className=" col-lg-12 d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
              {selectedItem?.document_status === "pending" || generateLetterData?.document_status === "pending" ? (
                <Button
                  className="btn btn-primary sub-btn"
                  variant="primary"
                  type="button"
                  label="Approve"
                  onClick={() => approvedDocument()}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <CoreLoader show={loading} />
        </div>
      </div>
    </>
  );
}
