import { AlertMessage, Button, CoreLoader, Dropdown, Input, Label, Textarea } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function CreateResignation() {
  const [resignationTypeOptions, setResignationTypeOptions] = useState([]);
  const [resignationDetail, setResignationDetail] = useState({
    resignationType: "",
    noticeDate: "",
    resignationDate: "",
    reason: "",
  });

  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const noticeDate = today.toISOString().split("T")[0];
    const resignationDate = new Date(today.setMonth(today.getMonth() - 1)).toISOString().split("T")[0];

    setResignationDetail((prevState) => ({
      ...prevState,
      noticeDate,
      resignationDate,
    }));

    getResignationTypeOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResignationDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getResignationTypeOptions = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes("offboarding")
      .then((response) => {
        setResignationTypeOptions(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching resignation type options:", error);
        setLoading(false);
      });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!resignationDetail.resignationType) {
      errors.resignationType = "Resignation Type is required";
      isValid = false;
    }

    if (!resignationDetail.noticeDate) {
      errors.noticeDate = "Notice Date is required";
      isValid = false;
    }

    if (!resignationDetail.resignationDate) {
      errors.resignationDate = "Resignation Date is required";
      isValid = false;
    }

    if (resignationDetail.reason && resignationDetail.reason.length < 10) {
      errors.resignationReason = "Reason must be at least 10 letters if provided";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const createResignation = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const payload = {
      resignation_type: resignationDetail.resignationType,
      notice_date: resignationDetail.noticeDate,
      resignation_date: resignationDetail.resignationDate,
      reason: resignationDetail.reason,
    };

    setLoading(true);
    apiManager
      .CreateOffboarding(payload)
      .then((res) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
        navigate("/hrtools/e-exit/Offboarding#pending");
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
      });
  };

  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="boxshadow borderradius">
              <div className="p-5">
                <div className="form row p-3 d-flex justify-content-center">
                  <Label label="Resignation Type" parentclasses="col-xxl-2 col-xl-4 col-lg-3 col-md-4 col-sm-6" />
                  <Dropdown
                    parentclasses="col-lg-6 col-xxl-6 col-xl-8 col-lg-9 col-md-8"
                    value={resignationDetail.resignationType}
                    name="resignationType"
                    onChange={handleInputChange}
                    options={resignationTypeOptions}
                    placeholder="Select Resignation Type"
                  />
                  {errors.resignationType && (
                    <div className="text-danger d-flex justify-content-center">{errors.resignationType}</div>
                  )}
                </div>

                <div className="row p-3 d-flex justify-content-center">
                  <Label label="Resignation Date" parentclasses="col-xxl-2 col-xl-4 col-lg-3 col-md-4 col-sm-6" />
                  <Input
                    parentclasses="col-lg-6 col-xxl-6 col-xl-8 col-lg-9 col-md-8"
                    inputclasses="form-controlcustom"
                    type="date"
                    name="resignationDate"
                    value={resignationDetail.resignationDate}
                    readOnly
                  />
                  {errors.resignationDate && (
                    <div className="text-danger d-flex justify-content-center">{errors.resignationDate}</div>
                  )}
                </div>

                <div className="row p-3 d-flex justify-content-center">
                  <Label label="Notice Date" parentclasses="col-xxl-2 col-xl-4 col-lg-3 col-md-4 col-sm-6" />
                  <Input
                    parentclasses="col-lg-6 col-xxl-6 col-xl-8 col-lg-9 col-md-8"
                    inputclasses="form-controlcustom"
                    type="date"
                    name="noticeDate"
                    value={resignationDetail.noticeDate}
                    readOnly
                  />
                  {errors.noticeDate && (
                    <div className="text-danger d-flex justify-content-center">{errors.noticeDate}</div>
                  )}
                </div>

                <div className="row p-3 d-flex justify-content-center">
                  <Label label="Reason" parentclasses="col-xxl-2 col-xl-4 col-lg-3 col-md-4 col-sm-6" />
                  <Textarea
                    parentclasses="col-lg-6 col-xxl-6 col-xl-8 col-lg-9 col-md-8"
                    name="reason"
                    value={resignationDetail.reason}
                    onChange={handleInputChange}
                    maxLength={255}
                    rows={3}
                    cols={30}
                  />
                  {errors.resignationReason && (
                    <div className="text-danger d-flex justify-content-center">{errors.resignationReason}</div>
                  )}
                </div>
              </div>
              <div className="d-flex gap-3 justify-content-end subheaderbackground p-2 px-4">
                <Button onClick={createResignation} variant="primary" type="submit" label="Submit" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <CoreLoader show={loading} disableLines />
    </>
  );
}
