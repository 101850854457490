import React, { useState, useEffect } from "react";
import { AlertMessage, Button, CoreLoader, FloatingInput, Input } from "common/components";
import apiManager from "networking/baseAPIManager";
import { useFormik } from "formik";
import { NewPasswordSchema } from "utils/formValidationUtils";
import { useLocation, useNavigate } from "react-router-dom";
import usePasswordToggle from "common/components/usePasswordToggle";

const NewPassword = () => {
  const loctionData = useLocation();
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const mailData = loctionData.state;
  const token = mailData.token;
  const navigate = useNavigate();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [ConfirmPasswordInputType, ConfirmToggleIcon] = usePasswordToggle();

  const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
    initialValues: { password: "", confirmPassword: "" },
    validationSchema: NewPasswordSchema,
    onSubmit: (values) => {
      setLoading(true);
      apiManager
        .resetpassword({ ...values, token })
        .then((response) => {
          setLoading(false);
          setmsgType("success");
          setShowMSG(response?.data?.message || "New password updated successfully");
          navigate("/login");
        })
        .catch((error) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(error?.response?.data?.message || "failed to update New password");
          console.log(error, "errrrrrrr");
        });
    },
  });

  return (
    <>
      <h2 className="mb-4">
        <b>New Password</b>
      </h2>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <form onSubmit={handleSubmit}>
        <FloatingInput
          name="password"
          value={values.password}
          onChange={handleChange}
          type={PasswordInputType}
          placeholder="*******"
          label="Password"
          onBlur={handleBlur}
          isValid={touched.password && !errors.password}
          touched={touched.password}
          error={errors.password}
          icons={ToggleIcon}
        />

        <FloatingInput
          name="confirmPassword"
          value={values.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          type={ConfirmPasswordInputType}
          placeholder="*******"
          label="Confirm Password"
          isValid={touched.confirmPassword && !errors.confirmPassword}
          touched={touched.confirmPassword}
          error={errors.confirmPassword}
          icons={ConfirmToggleIcon}
        />

        <div className="d-grid">
          <Button type="submit" label="Reset Password"></Button>
        </div>
      </form>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default NewPassword;
